import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProtectedRoute, MainPage } from "./Routes";
import { AlertState } from "./Components/UI";
import { ChnagePasswordModal } from "./models";
import {
  Landing,
  Dashboard,
  Login,
  ForgetPassword,
  Register,
  PagenotFound,
  KundaliMatching,
  Kundali,
  Privacypolicy,
} from "./Pages";
import "./Styled/App.scss";

function App() {
  const { alertShow } = useSelector((state) => state.ui);
  const { resetPasswordModalStatus } = useSelector((state) => state.auth);

  return (
    <>
      {alertShow && <AlertState />}
      {resetPasswordModalStatus && (
        <ChnagePasswordModal isOpen={resetPasswordModalStatus} />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainPage />
            </ProtectedRoute>
          }
        >
          <Route index element={<Navigate to="/dashboard" replace />} />
          <Route path="dashboard" exact element={<Dashboard />} />
          <Route path="kundli" exact element={<Kundali />} />
          <Route path="kundli-matching" exact element={<KundaliMatching />} />
        </Route>
        <Route path="/landing" exact element={<Landing />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/register" exact element={<Register />} />
        <Route
          path="/password/forget-password"
          exact
          element={<ForgetPassword />}
        />
        <Route path="/privacypolicy" exact element={<Privacypolicy />} />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
    </>
  );
}

export default App;
