import React from "react";
import { Link } from "react-router-dom";

const AppLogo = ({ token }) => {
  return (
    <Link className="d-flex" to={token ? "/dashboard" : "/landing"}>
      {/* <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src="/assets/images/menus.png" alt="icon" width="35" />
      </button> */}
      <span to="" className="logo-box">
        <img src="/assets/images/logo1.png" alt="logo" />
      </span>
    </Link>
  );
};

export default AppLogo;
