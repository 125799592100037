import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import useAuthApp from "../hooks/useAuthApp";
import { createKundli } from "../store/kundli/kundli-action";
import { onFailedMsg } from "../helper/showHideAlert";
import { createMatchingKundli } from "../store/matching-kundli/matchingKundli-action";
import { AnimatedSpinner } from "../Components/UI";

const SelectLanguageModal = ({ isOpen, onHideHandler, data }) => {
  const { navigate, authFetch, dispatch } = useAuthApp();
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const onCloseModal = () => {
    setSelectedLanguage("");
    onHideHandler();
  };

  const body = {
    userData: {
      ...data,
      chart: "north",
      ayanmasha: "LAHIRI",
      node: "true",
      house: "shripati",
    },
    lang: selectedLanguage,
  };

  const onSubmit = async () => {
    if (
      body?.userData?.hasOwnProperty("f_name") &&
      body?.userData?.hasOwnProperty("m_name")
    ) {
      if (selectedLanguage) {
        setLoading(true);
        await dispatch(createMatchingKundli(authFetch, navigate, body));
        setLoading(false);
      } else {
        onFailedMsg(dispatch, "Please Select Language");
      }
    } else {
      if (selectedLanguage) {
        setLoading(true);
        await dispatch(createKundli(authFetch, navigate, body));
        setLoading(false);
      } else {
        onFailedMsg(dispatch, "Please Select Language");
      }
    }
  };

  if (loading) {
    return (
      <Modal
        size="sm"
        show={loading}
        onHide={() => {}}
        centered
        style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
      >
        <div className="modal-spinner-container">
          <AnimatedSpinner />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      size="sm"
      show={isOpen}
      onHide={onCloseModal}
      centered
      backdrop={`${loading ? "static" : true}`}
    >
      <div
        id="languageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="languageModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog m-0 p-0" role="document">
          <div className="modal-content">
            <div className="modal-header m-0 p-2">
              <h5 className="modal-title " id="languageModalLabel">
                Choose Language
              </h5>
              <button
                type="button"
                className={`btn-close `}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCloseModal}
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="language"
                      id="hindiRadio"
                      value="hi"
                      checked={selectedLanguage === "hi" ? true : false}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="hindiRadio">
                      Hindi
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="language"
                      id="englishRadio"
                      value="en"
                      checked={selectedLanguage === "en" ? true : false}
                      onChange={handleLanguageChange}
                    />
                    <label className="form-check-label" htmlFor="englishRadio">
                      English
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-secondary `}
                data-dismiss="modal"
                onClick={onCloseModal}
              >
                Close
              </button>
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={onSubmit}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectLanguageModal;
