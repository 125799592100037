import React from "react";

const AstroDetails = ({ matchingKundli }) => {
  return (
    <div className="kundli_basi row  m-0">
      <div className="reposive_teble ">
        <table className="w-100">
          <thead>
            <tr>
              <th>Male</th>
              <th>Astro Details</th>
              <th>Female</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Varna
                }
              </td>
              <td>Varna</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Varna
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Vashya
                }
              </td>
              <td>Vashya</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Vashya
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Yoni
                }
              </td>
              <td>Yoni</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Yoni
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Gan
                }
              </td>
              <td>Gan</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Gan
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Nadi
                }
              </td>
              <td>Nadi</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Nadi
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.SignLord
                }
              </td>
              <td>Sign Lord</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.SignLord
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Naksahtra
                }
              </td>
              <td>Nakshtra</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Naksahtra
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.NaksahtraLord
                }
              </td>
              <td>Nak Lord</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.NaksahtraLord
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Charan
                }
              </td>
              <td>Charan</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Charan
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Yog
                }
              </td>
              <td>Yog</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Yog
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Karan
                }
              </td>
              <td>Karan</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Karan
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.Tithi
                }
              </td>
              <td>Tithi</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.Tithi
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.yunja
                }
              </td>
              <td>Yunja</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.yunja
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.tatva
                }
              </td>
              <td>Tatva</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.tatva
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.name_alphabet
                }
              </td>
              <td>Alphabet</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.name_alphabet
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.male_astro_details?.paya
                }
              </td>
              <td>Paya(metal)</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.astro_details
                    ?.female_astro_details?.paya
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AstroDetails;
