import React from "react";

const NumerologyPrediction = ({ kundli }) => {
  return (
    <div className="kundli_basi row  m-0">
      <div>
        <h4 className="heding_bx">{kundli?.numero_place_vastu?.title}</h4>
        <p className="my-3">{kundli?.numero_place_vastu?.description}</p>
      </div>
      <div>
        <h4 className="heding_bx">{kundli?.numero_fav_lord?.title}</h4>
        <p className="my-3">{kundli?.numero_fav_lord?.description}</p>
      </div>
      <div>
        <h4 className="heding_bx">{kundli?.numero_fav_mantra?.title}</h4>
        <p className="my-3">{kundli?.numero_fav_mantra?.description}</p>
      </div>
      <div>
        <h4 className="heding_bx">{kundli?.numero_fav_time?.title}</h4>
        <p className="my-3">{kundli?.numero_fav_time?.description}</p>
      </div>
      <div>
        <h4 className="heding_bx">{kundli?.numero_fasts_report?.title}</h4>
        <p className="my-3">{kundli?.numero_fasts_report?.description}</p>
      </div>
    </div>
  );
};

export default NumerologyPrediction;
