import React from "react";

const MatchConclusion = ({ matchingKundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <div>
        <h5 className="heding_bx">OverAll Analysis</h5>
        <div className="kundli_name">
          <p className="col-lg-3 col-12">
            Ashtkoota Match :{" "}
            {
              matchingKundli?.callAllMatch?.match_making_report?.ashtakoota
                ?.received_points
            }{" "}
            / 36
          </p>
          <p className="col-lg-3 col-12">
            Manglik Match :{" "}
            {matchingKundli?.callAllMatch?.match_making_report?.manglik
              ?.status === true
              ? "Yes"
              : "No"}
          </p>
          <p className="col-lg-3 col-12">
            Rajju Dosha :{" "}
            {matchingKundli?.callAllMatch?.match_making_report?.rajju_dosha
              ?.status === true
              ? "Yes"
              : "No"}
          </p>
          <p className="col-lg-3 col-12">
            Vedha Dosha :{" "}
            {matchingKundli?.callAllMatch?.match_making_report?.vedha_dosha
              ?.status === true
              ? "Yes"
              : "No"}
          </p>
        </div>
      </div>
      <div>
        <h4 className="heding_bx">Conclusion</h4>
        <p className="my-3">
          {
            matchingKundli?.callAllMatch?.match_making_report?.conclusion
              ?.match_report
          }
        </p>
      </div>
    </div>
  );
};

export default MatchConclusion;
