import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./ui/ui-slice";
import userSlice from "./user/user-slice";
import authSlice from "./auth/auth-slice";
import { openDB } from "idb";
import kundliUserSlice from "./kundliUser/kundliUser-slice";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  persistCombineReducers,
} from "redux-persist";
import presistSlice from "./presist/presist-slice";
import kundliSlice from "./kundli/kundli-slice";
import matchingKundliSlice from "./matching-kundli/matchingKundli-slice";
import zodiacSlice from "./zodiac/zodiac-slice";

async function createDB() {
  const db = await openDB("redux-persist", 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("redux-store")) {
        db.createObjectStore("redux-store");
      }
    },
  });
  return db;
}
const persistConfig = {
  key: "root",
  // storage,
  storage: {
    async getItem(key) {
      const db = await createDB();
      return db.get("redux-store", key);
    },
    async setItem(key, value) {
      const db = await createDB();
      return db.put("redux-store", value, key);
    },
    async removeItem(key) {
      const db = await createDB();
      return db.delete("redux-store", key);
    },
  },
  whitelist: ["presist"],
  transforms: [
    {
      in: (state) => {
        let encData = {
          isRememberMe: state?.isRememberMe,
        };
        return encData;
      },
      out: (state) => {
        return state;
      },
    },
  ],
};

const rootReducer = persistCombineReducers(persistConfig, {
  ui: uiSlice.reducer,
  user: userSlice.reducer,
  auth: authSlice.reducer,
  presist: presistSlice.reducer,
  kundliUser: kundliUserSlice.reducer,
  kundli: kundliSlice.reducer,
  matchingKundli: matchingKundliSlice.reducer,
  zodiac: zodiacSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  ],
});

export const persistedStore = persistStore(store);
export default store;
