import React, { useState } from "react";

const PlanetAshtak = ({ kundli }) => {
  const [planet, setPlanet] = useState("planet_ashtak/sun");

  const handlePlanetChange = (event) => {
    setPlanet(event.target.value);
  };
  return (
    <div className="kundli_basi row  justify-content-center m-0">
      <h3 className="heding_bx">Select Planet</h3>
      <div className="col-lg-4 col-12 my-3">
        <select
          className="form-control"
          value={planet}
          onChange={handlePlanetChange}
        >
          <option value="planet_ashtak/sun">Ashtak Varga For Sun</option>
          <option value="planet_ashtak/moon">Ashtak Varga For Moon</option>
          <option value="planet_ashtak/mars">Ashtak Varga For Mars</option>
          <option value="planet_ashtak/mercury">
            Ashtak Varga For Mercury
          </option>
          <option value="planet_ashtak/jupiter">
            Ashtak Varga For Jupiter
          </option>
          <option value="planet_ashtak/venus">Ashtak Varga For Venus</option>
          <option value="planet_ashtak/saturn">Ashtak Varga For Saturn</option>
        </select>
      </div>
      <div className="reposive_teble ">
        <table className="w-100">
          <thead>
            <tr>
              <th>Planet Zodiac</th>
              <th>Sun</th>
              <th>Moon</th>
              <th>Mars</th>
              <th>Mercury</th>
              <th>Jupiter</th>
              <th>Venus</th>
              <th>Saturn</th>
              <th>Ascendant</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aries</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aries?.total}</td>
            </tr>
            <tr>
              <td>TAURUS</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.taurus?.total}</td>
            </tr>
            <tr>
              <td>GEMINI</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.gemini?.total}</td>
            </tr>
            <tr>
              <td>CANCER</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.cancer?.total}</td>
            </tr>
            <tr>
              <td>LEO</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.leo?.total}</td>
            </tr>
            <tr>
              <td>VIRGO</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.virgo?.total}</td>
            </tr>
            <tr>
              <td>LIBRA</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.libra?.total}</td>
            </tr>
            <tr>
              <td>SCORPIO</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.scorpio?.total}</td>
            </tr>
            <tr>
              <td>SAGITTARIUS</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.sagittarius?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.sagittarius?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.sagittarius?.mars}</td>
              <td>
                {kundli[`${planet}`]?.ashtak_points?.sagittarius?.mercury}
              </td>
              <td>
                {kundli[`${planet}`]?.ashtak_points?.sagittarius?.jupiter}
              </td>
              <td>{kundli[`${planet}`]?.ashtak_points?.sagittarius?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.sagittarius?.saturn}</td>
              <td>
                {kundli[`${planet}`]?.ashtak_points?.sagittarius?.ascendant}
              </td>
              <td>{kundli[`${planet}`]?.ashtak_points?.sagittarius?.total}</td>
            </tr>
            <tr>
              <td>CAPRICORN</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.saturn}</td>
              <td>
                {kundli[`${planet}`]?.ashtak_points?.capricorn?.ascendant}
              </td>
              <td>{kundli[`${planet}`]?.ashtak_points?.capricorn?.total}</td>
            </tr>
            <tr>
              <td>AQUARIUS</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.aquarius?.total}</td>
            </tr>
            <tr>
              <td>PISCES</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.sun}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.moon}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.mars}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.mercury}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.jupiter}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.venus}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.saturn}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.ascendant}</td>
              <td>{kundli[`${planet}`]?.ashtak_points?.pisces?.total}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanetAshtak;
