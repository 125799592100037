import { Fragment } from "react";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { FaRegEye } from "react-icons/fa";

const ShowHidePwd = ({ isShowPwd, onTogglePassword }) => {
  return (
    <Fragment>
      {isShowPwd ? (
        <BsFillEyeSlashFill onClick={onTogglePassword} className="icon_pass" />
      ) : (
        <FaRegEye onClick={onTogglePassword} className="icon_pass" />
      )}
    </Fragment>
  );
};

export default ShowHidePwd;
