import React from "react";

const PitraDosha = ({ kundli }) => {
  return (
    <div className="kundli_basi row m-0 ">
      <div>
        <h4 className="heding_bx">What is Pitra Dosha</h4>
        <p>{kundli?.pitra_dosha_report?.what_is_pitri_dosha}</p>
      </div>
      <div>
        <h4
          className="p-2 text-light"
          style={{
            backgroundColor: `${
              kundli?.pitra_dosha_report?.is_pitri_dosha_present === true
                ? "Red"
                : "Green"
            }`,
          }}
        >
          {kundli?.pitra_dosha_report?.conclusion}
        </h4>
      </div>

      <div>
        <h6 className="heding_bx">Rule Matched</h6>
        {kundli?.pitra_dosha_report?.rules_matched?.map((rule, index) => (
          <p key={index}>{rule}</p>
        ))}
      </div>

      <div>
        {kundli?.pitra_dosha_report?.remedies?.map((remed, index) =>
          index === 0 ? (
            <h6 key={index}>{remed}</h6>
          ) : (
            <p key={index}>{remed}</p>
          )
        )}
      </div>

      <div>
        {kundli?.pitra_dosha_report?.effects?.map((effect, index) =>
          index === 0 ? (
            <h6 key={index}>{effect}</h6>
          ) : (
            <p key={index}>{effect}</p>
          )
        )}
      </div>
    </div>
  );
};

export default PitraDosha;
