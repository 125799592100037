import { zodiacAction } from "./zodiac-slice";

export const zodiacDetails = (authFetchGET, body) => {
  return async (dispatch) => {
    try {
      let { data } = await authFetchGET.get(
        `/zodiac/getZodiacDetails?type=${body.type}&zodiac=${body.zodiac}&languageId=${body.languageId}&time=${body.time}&timezone=${body.timezone}`
      );
      if (data && data.status === true) {
        dispatch(zodiacAction.updateZodiac({ zodiac: data.data.data }));
      }
    } catch (error) {
      dispatch(zodiacAction.onEmptyZodiacData());
      console.log("error", error);
    }
  };
};
