import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const YoginiDasha = ({ kundli }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const showSubDasha = (clickedIndex) => {
    setActiveIndex((prevState) => {
      if (activeIndex === clickedIndex) {
        return null;
      } else {
        return clickedIndex;
      }
    });
  };

  return (
    <div className="kundli_basi row justify-content-center m-0">
      <div>
        <h4 className="heding_bx">Yogini Dasha</h4>
        <p className="mt-3">
          Just like Vimshottari, Yogini Dasha is also an important dasha of
          Vedic astrology. In this also the Nakshatra dasha is based on the
          position of the moon. Each is assigned to the Yogini while each has
          corresponding node or planets. There are a total of 8 Yoginis while
          Ketu does not play a role here. 36 years is the total period of Yogini
          Dasha. To interpret Yogini dasha, the strength of the planets are very
          important.
        </p>
      </div>
      <div className="reposive_teble">
        <table className="w-100">
          <thead>
            <tr>
              <th>Planet</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {kundli?.yogini_dasha?.map((dasha, index) => (
              <React.Fragment key={index}>
                <tr
                  className="cursor-pointer"
                  onClick={() => showSubDasha(index)}
                >
                  <td>{dasha?.planet}</td>
                  <td>{dasha?.start}</td>
                  <td>{dasha?.end}</td>
                </tr>
                {activeIndex === index && (
                  <tr>
                    <td colSpan="3" className="m-0 p-0">
                      <Dropdown className="in_td_box">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Planet</th>
                              <th>Start Date</th>
                              <th>End Date </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dasha?.subYoginiDasha.map((subDasha, subIndex) => (
                              <tr key={subIndex}>
                                <td> {subDasha?.planet} </td>
                                <td> {subDasha?.start} </td>
                                <td> {subDasha?.end} </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Dropdown>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YoginiDasha;
