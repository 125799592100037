import React from "react";

const MatchManglik = ({ matchingKundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <div className="col-lg-6 col-12  box_gemstone_card">
        <h5>
          {
            matchingKundli?.callAllMatch?.birth_details?.male_astro_details
              ?.name
          }
          -Manglik Analysis
        </h5>
        <p>
          Manglik Percentage :-{" "}
          {matchingKundli?.m_manglik?.percentage_manglik_present}
        </p>
        <p>
          Are you Manglik :-{" "}
          {matchingKundli?.m_manglik?.is_present === true ? "Yes" : "No"}
        </p>

        <p>{matchingKundli?.m_manglik?.manglik_report}</p>
      </div>
      <div className="col-lg-6 col-12 box_gemstone_card">
        <h5>
          {
            matchingKundli?.callAllMatch?.birth_details?.female_astro_details
              ?.name
          }
          -Manglik Analysis
        </h5>
        <p>
          Manglik Percentage :-{" "}
          {matchingKundli?.f_manglik?.percentage_manglik_present}
        </p>
        <p>
          Are you Manglik :-
          {matchingKundli?.f_manglik?.is_present === true ? "Yes" : "No"}
        </p>

        <p>{matchingKundli?.f_manglik?.manglik_report}</p>
      </div>
    </div>
  );
};

export default MatchManglik;
