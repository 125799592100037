import { uiActions } from "../store/ui/ui-slice";

export const onSuccess = (dispatch, msg) => {
  dispatch(
    uiActions.showAlert({
      alertShow: true,
      alertType: "alert-success",
      alertText: msg,
    })
  );
};

export const onFailedMsg = (dispatch, msg) => {
  dispatch(
    uiActions.showAlert({
      alertShow: true,
      alertType: "alert-danger",
      alertText: msg || "Something went wrong",
    })
  );
};
