import React from "react";

const LineLoader = () => {
  return (
    <>
      <style>{`
        @keyframes lineAnim {
          0% {
            left: -40%;
          }
          50% {
            left: 20%;
            width: 80%;
          }
          100% {
            left: 100%;
            width: 100%;
          }
        }

        .loader-line {
          width: 200px;
          height: 5px;
          position: relative;
          overflow: hidden;
          background-color: #ddd;
          border-radius: 20px;
          margin-top : 10px
        }

        .loader-line-inner {
          position: absolute;
          left: -50%;
          height: 100%;
          width: 40%;
          background-color: coral;
          animation: lineAnim 1s linear infinite;
          border-radius: 20px;
        }
      `}</style>
      <div className="loader-line">
        <div className="loader-line-inner"></div>
      </div>
    </>
  );
};

export default LineLoader;
