import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { debounce } from "lodash";
import axios from "axios";
import useAuthApp from "../hooks/useAuthApp";
import {
  createKundliUser,
  editKundliUserDetails,
} from "../store/kundliUser/kundliUser-action";
import { CountryListoptions, Error } from "../Components/UI";
import DatePrompt from "./DatePrompt";

const KundliUsersModal = ({ isOpen, onHideHandler, data }) => {
  const { dispatch, authFetch, authFetchGET } = useAuthApp();
  const [values, setValues] = useState({
    nameu: "",
    gender: "",
    day: "",
    month: "",
    year: "",
    hour: "",
    min: "",
    country: "india",
    birth: "",
    lat: "",
    lon: "",
    tzone: 5.5,
    language: "english",
  });
  const [location, setLocation] = useState([]);
  const [date, setDate] = useState(new Date());
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const onCloseModal = () => {
    setValues({
      nameu: "",
      gender: "",
      day: "",
      month: "",
      year: "",
      hour: "",
      min: "",
      country: "india",
      birth: "",
      lat: "",
      lon: "",
      tzone: 5.5,
      language: "english",
    });
    setErrors({});
    onHideHandler();
  };

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      let selectedDate = new Date(data?.year, data.month - 1, data.day);
      setDate(selectedDate);
      setValues(data);
    }
  }, [data]);

  const takeLatLong = (data) => {
    setValues({
      ...values,
      birth: data.place,
      lat: data.latitude,
      lon: data.longitude,
    });
    setLocation([]);
  };

  const onChnageValue = (e) => {
    setValues((previousState) => {
      return {
        ...previousState,
        [e.target.name]: e.target.value,
      };
    });

    setErrors((previousState) => {
      return {
        ...previousState,
        [e.target.name]:
          e.target.value.trim() === "" ? `${e.target.title} is required` : "",
      };
    });
  };

  const fetchLocation = async (country, place) => {
    try {
      let body = {
        country: country,
        name: place,
      };
      const { data } = await axios.post(
        "https://geo.vedicrishi.in/places",
        body
      );
      setLocation(data);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };
  const debouncedFetchLocation = debounce(fetchLocation, 1000);

  const onChnageCountryPlace = (e) => {
    let value = e.target.value?.trim();
    if (value && value?.length > 2) {
      debouncedFetchLocation(values.country, values.birth);
    } else {
      setLocation([]);
    }
    setValues((previousState) => {
      return {
        ...previousState,
        [e.target.name]: value,
      };
    });

    setErrors((previousState) => {
      return {
        ...previousState,
        [e.target.name]: !value ? `${e.target.title} is required` : "",
      };
    });
  };

  const isFormValidate = () => {
    // let check = /^[A-Za-z\s]+$/.test(values.userName);
    if (
      values.nameu?.trim() === "" ||
      values.gender?.trim() === "" ||
      values.day === "" ||
      values.month === "" ||
      values.year === "" ||
      values.hour === "" ||
      values.min === "" ||
      values.country?.trim() === "" ||
      values.birth?.trim() === "" ||
      values.lat?.trim() === "" ||
      values.lon?.trim() === ""
    ) {
      setErrors({
        ...errors,
        nameu: values.nameu?.trim() === "" ? "Full Name is required" : "",
        gender: values?.gender?.trim() === "" ? "Gender is required" : "",
        hour: values.hour?.trim() === "" ? "Birth Hour is required" : "",
        min: values.min?.trim() === "" ? "Birth Minute is required" : "",
        country:
          values.country?.trim() === "" ? "Birth Country is required" : "",
        birth: values.birth?.trim() === "" ? "Birth Place is required" : "",
      });
      return false;
    }
    return true;
  };

  const hours = Array.from({ length: 24 }, (_, index) => index);
  const minutes = Array.from({ length: 60 }, (_, index) => index);

  const convertTo12HourFormat = (hour) => {
    if (hour === 0) return "12 AM";
    else if (hour === 12) return "12 PM";
    else if (hour < 12) return `${hour} AM`;
    else return `${hour - 12} PM`;
  };

  const createUser = async (e) => {
    if (data && Object.keys(data).length > 0) {
      e.preventDefault();
      setLoading(true);
      await dispatch(
        editKundliUserDetails(values._id, values, authFetch, authFetchGET)
      );
      setLoading(false);
      onCloseModal();
    } else {
      e.preventDefault();
      if (isFormValidate()) {
        setLoading(true);
        const trimmedValues = { ...values, nameu: values.nameu.trim() };
        await dispatch(
          createKundliUser(trimmedValues, authFetch, authFetchGET)
        );
        setLocation([]);
        setValues({
          nameu: "",
          gender: "",
          day: "",
          month: "",
          year: "",
          hour: "",
          min: "",
          country: "India",
          birth: "",
          lat: "",
          lon: "",
          tzone: 5.5,
          language: "english",
        });
        setLoading(false);
        onCloseModal();
        return true;
      }
    }
  };

  const onDateChange = (date) => {
    setDate(date);
    if (date) {
      setValues({
        ...values,
        day: String(date.getDate()),
        month: String(date.getMonth() + 1),
        year: String(date.getFullYear()),
      });
    } else {
      setValues({
        ...values,
        day: "",
        month: "",
        year: "",
      });
    }
  };

  return (
    <Modal
      size="md"
      show={isOpen}
      onHide={onCloseModal}
      centered
      backdrop={`${loading ? "static" : true}`}
    >
      {" "}
      <div
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog m-0 p-0">
          <div className="modal-content">
            <div className="modal-header m-0 p-2">
              <h5 className="modal-title" id="exampleModalLabel">
                {Object.values(data)?.length > 0
                  ? `${data?.nameu} Update Profile`
                  : "Add New Profile"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onCloseModal}
                disabled={loading}
              ></button>
            </div>
            <div className="modal-body">
              <form
                id="survey-form"
                className="form-wrap malti_card"
                onSubmit={createUser}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group row">
                      <label htmlFor="name">Enter Your Full Name</label>
                      <div className="col-lg-8 col-12 m-0 p-0 px-1">
                        <input
                          value={values.nameu}
                          onChange={onChnageValue}
                          title="Name"
                          type="text"
                          name="nameu"
                          placeholder="Your Name"
                          className="form-control"
                        />
                        {errors?.nameu && <Error>{errors?.nameu}</Error>}
                      </div>
                      <div className="col-lg-4 col-12 m-0 p-0 px-1">
                        <select
                          title="Gender"
                          name="gender"
                          className="form-control"
                          value={values.gender}
                          onChange={onChnageValue}
                        >
                          <option value="">Select</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </select>
                        {errors?.gender && <Error>{errors?.gender}</Error>}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group row">
                      <label htmlFor="name">Select Your Birth Date</label>
                      {/* <div className="col-lg-3 col-12 m-0 p-1">
                        <input
                          value={values.day}
                          onChange={onChnageValue}
                          title="Birth Day"
                          type="text"
                          name="day"
                          placeholder="DD"
                          className="form-control"
                        />
                        {errors?.day && <Error>{errors?.day}</Error>}
                      </div>
                      <div className="col-lg-6 col-12 m-0 p-1">
                        <select
                          title="Birth Month"
                          name="month"
                          className="form-control"
                          value={values.month}
                          onChange={onChnageValue}
                        >
                          <option value="">Birth Month</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                        </select>
                        {errors?.month && <Error>{errors?.month}</Error>}
                      </div>
                      <div className="col-lg-3 col-12 m-0 p-1">
                        <input
                          title="Birth Year"
                          value={values.year}
                          onChange={onChnageValue}
                          type="text"
                          name="year"
                          placeholder="YYYY"
                          className="form-control"
                        />
                        {errors?.year && <Error>{errors?.year}</Error>}
                      </div> */}

                      <DatePrompt
                        selected={date}
                        onChange={(date) => onDateChange(date)}
                        maxDate={date}
                      />
                      <div className="col-lg-6 col-12 m-0 p-1">
                        <select
                          title="Birth Hour"
                          name="hour"
                          className="form-control"
                          value={values.hour}
                          onChange={onChnageValue}
                        >
                          <option value="">Birth Hour</option>
                          {hours.map((hour) => (
                            <option key={hour} value={hour}>
                              {convertTo12HourFormat(hour)}
                            </option>
                          ))}
                        </select>
                        {errors?.hour && <Error>{errors?.hour}</Error>}
                      </div>
                      <div className="col-lg-6 col-12 m-0 p-1">
                        <select
                          title="Birth Minute"
                          name="min"
                          className="form-control"
                          value={values.min}
                          onChange={onChnageValue}
                        >
                          <option value="">Birth Minute</option>
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute < 10 ? `0${minute}` : minute}{" "}
                            </option>
                          ))}
                        </select>
                        {errors?.min && <Error>{errors?.min}</Error>}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="form-group row">
                      <label htmlFor="name">Select Your Birth Place</label>

                      <div className="col-lg-4 col-12 m-0 p-0 px-1">
                        <select
                          title="Birth Country"
                          name="country"
                          className="form-control"
                          required
                          value={values.country}
                          onChange={onChnageValue}
                        >
                          <CountryListoptions />
                        </select>
                      </div>
                      <div className="col-lg-8 col-12 m-0 p-0 px-1 position-relative">
                        <input
                          title="Birth Place"
                          value={values.birth}
                          onChange={onChnageCountryPlace}
                          type="text"
                          name="birth"
                          placeholder="Type Birth City/District"
                          className="form-control"
                        />
                        {errors?.birth && <Error>{errors?.birth}</Error>}
                        <ul className="dropdaune_box">
                          {location.map((data, index) => (
                            <li key={index} onClick={() => takeLatLong(data)}>
                              {data.place}
                              {/* Latitude: {location.latitude}, Longitude: {location.longitude} */}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 col-12 d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-outline-danger mx-2"
                      data-bs-dismiss="modal"
                      onClick={onCloseModal}
                      disabled={loading}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      id="submit"
                      disabled={loading}
                      className="btn save_butt"
                    >
                      {loading ? "Loading" : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default KundliUsersModal;
