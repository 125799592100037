import React from "react";
import { Link } from "react-router-dom";

const Privacypolicy = () => {
  return (
    <div className="container">
      <div className="privacypolicy_box row">
        <h1 className="heding_bx">Privacy Policy  Bhrigu Jyotish</h1>
        <p>
          Bhrigu Jyotish is liable to secure the privacy of the user of our
          website as we understand your concern about personal data. We are
          committed to secure the confidentiality of the website user,
          astrologer, registered user, or visitor. We describe the privacy
          policy below and explain how we collect and use the information that
          we gained through our website users. It's completely your consent to
          read our privacy policy before accessing the website.
          <h6 className="link_text">What Information we collect?</h6>
        </p>
        
        <h2 className="heding_bx">1. Personal information</h2>
        <p>  We collect certain information which is personally identifiable
        information, namely information and birth details that identifies an
        individual or may with reasonable effort identify an individual, or may
        be of private nature (Personal Information). In addition, we treat
        information which is specifically connected or linked to any Personal
        Information - as long as such connection or linkage exists - as Personal
        Information. The Personal Information we collect includes the following:
        When you decide to register with our Service, or when you decide to
        access or use our Service, you will be request to provide your full name
        and your phone number. To complete registration, we may contact you with
        the number your provided us (via inbound call) in order to authenticate
        your identity and prevent fraud.
        </p>
        <h2 className="heding_bx">2. How do we safeguard your information?</h2>
        <p> We take great care in implementing and maintaining the security of the
        Service and your information. We employ industry standard procedures and
        policies to ensure the safety of our user information, and prevent
        unauthorized use of any such information. If you feel that your privacy
        was treated not in accordance with our policy, or if any person
        attempted to abuse our service or acted in an inappropriate manner,
        please contact us directly <Link className="link_text"> atanildesai10310@gmail.com </Link> we
        will remove the same.
        </p>
        <h1 className="heding_bx">User's Agreement</h1>
        <p>  This Privacy Policy initiates to inform individuals and website users
        about the website's purpose in terms of their sensitive data. The
        privacy policy that may be updated and amended over time deals with the
        supply and collection of the information gained from the user's personal
        data, including birth details, contact details, and several forecasts
        made on the website and also dispenses how much data the website uses
        and for what purpose. If you access the website, it's your
        responsibility to be aware of the Privacy Policy. Using this site
        indicates that you have a complete concern about the terms and privacy.
        Plus, please don't proceed to the site if you disagree with the privacy
        policy and terms and conditions. Your continuing access to the website
        confirms that you have consent and understanding of the privacy policy.
        It's requested to carefully read the Privacy Policy and Terms before
        using the site.
        </p>
        <h1 className="heding_bx">* Cookies</h1>
        <p>  Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
        This Service does not use these cookies explicitly. You have the option
        to either accept or refuse these cookies and know when a cookie is being
        sent to your device. If you choose to refuse our cookies, you may not be
        able to use some portions of this Service.
        </p>
        <h1 className="heding_bx"> * Security</h1>
        <p>  we value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and I cannot guarantee
        its absolute security.
        </p>
        <h1 className="heding_bx">* Contact Us</h1>
        <p>  <Link className="link_text"> anildesai10310@gmail.com </Link>
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact me.
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
