import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import useAuthApp from "../hooks/useAuthApp";
import { deleteKundliUser } from "../store/kundliUser/kundliUser-action";

function DeleteConfirmationModal({ isOpen, onHide, id, userName }) {
  const { dispatch, authFetch, authFetchGET } = useAuthApp();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    await dispatch(deleteKundliUser(id, authFetch, authFetchGET));
    setLoading(false);
    onHide();
  };

  return (
    <Modal
      size="md"
      show={isOpen}
      className="model_card_main"
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <h6>Are you sure you want to delete {userName}?</h6>
      </Modal.Header>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onDelete} disabled={loading}>
          {loading ? "Loading" : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteConfirmationModal;
