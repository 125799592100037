import React from "react";

const FavorablePoints = ({ kundli }) => {
  return (
    <div className="kundli_basi  m-0">
      <div className="box_card row">
        <p className="col-lg-4 col-12">
          Your Name : {kundli?.numero_table?.name}
        </p>
        <p className="col-lg-4 col-12">
          Today Date : {kundli?.numero_table?.date}
        </p>
        <p className="col-lg-4 col-12">
          Radical Number : {kundli?.numero_table?.radical_number}
        </p>
        <p className="col-lg-4 col-12">
          Name Number : {kundli?.numero_table?.name_number}
        </p>
        <p className="col-lg-4 col-12">
          Destiny Number : {kundli?.numero_table?.destiny_number}
        </p>
        <p className="col-lg-4 col-12">
          Radical Ruler : {kundli?.numero_table?.radical_ruler}
        </p>
        <p className="col-lg-4 col-12">
          Friendly Number : {kundli?.numero_table?.friendly_num}
        </p>
        <p className="col-lg-4 col-12">
          Evil Numbers : {kundli?.numero_table?.evil_num}
        </p>
        <p className="col-lg-4 col-12">
          Neutral Number : {kundli?.numero_table?.neutral_num}
        </p>
        <p className="col-lg-4 col-12">
          Favourable Days : {kundli?.numero_table?.fav_day}
        </p>
        <p className="col-lg-4 col-12">
          Favourable Stone : {kundli?.numero_table?.fav_stone}
        </p>
        <p className="col-lg-4 col-12">
          Favourable Sub Stone : {kundli?.numero_table?.fav_substone}
        </p>
        <p className="col-lg-4 col-12">
          Favourable God : {kundli?.numero_table?.fav_god}
        </p>
        <p className="col-lg-4 col-12">
          Favourable Metal : {kundli?.numero_table?.fav_metal}
        </p>
        <p className="col-lg-4 col-12">
          Favourable Color : {kundli?.numero_table?.fav_color}
        </p>
        <p className="col-lg-4 col-12">
          Favourable Mantra : {kundli?.numero_table?.fav_mantra}
        </p>
      </div>
    </div>
  );
};

export default FavorablePoints;
