import { createSlice } from "@reduxjs/toolkit";

const matchingKundliSlice = createSlice({
  name: "matchingKundli",
  initialState: {
    matchingKundli: {},
  },
  reducers: {
    updateMatchingKundli(state, action) {
      state.matchingKundli = action.payload.matchingKundli;
    },
  },
});

export const matchingKundliActions = matchingKundliSlice.actions;
export default matchingKundliSlice;
