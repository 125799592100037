import { Outlet } from "react-router-dom";
import { Header, Footer } from "../Components/Layouts";

const MainPage = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};
export default MainPage;
