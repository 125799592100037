import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TiPencil } from "react-icons/ti";
import { Error, Button, BgImage } from "../../Components/UI/index.js";
import useAuthApp from "../../hooks/useAuthApp";
import { onSuccess, onFailedMsg } from "../../helper/showHideAlert";
import VerifyOTP from "./VerifyOTP.js";

const ForgetPassword = () => {
  const { authFetch, navigate, dispatch } = useAuthApp();
  const { token } = useSelector((state) => state.auth);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isSentOTP, setIsSentOTP] = useState(false);
  const [values, setValues] = useState({
    mobileNumber: "",
  });
  const [isUpdated, setIsUpdatedNumber] = useState(false);

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (token) {
      navigate("/dashboard ");
    } else {
      localStorage.clear();
    }
  }, [navigate, token]);

  const handleChangeMobile = (value, data) => {
    delete data?.format;
    if (isSentOTP) {
      setIsSentOTP(false);
      setIsUpdatedNumber(false);
    }
    setValues((previousState) => {
      return {
        ...previousState,
        mobileNumber: value.slice(data.dialCode.length),
      };
    });
    setErrors((previousState) => {
      return {
        ...previousState,
        mobile_number:
          value.slice(data.dialCode.length) === ""
            ? "Mobile Number is required"
            : "",
      };
    });
  };
  const isFormValidate = () => {
    if (
      Number(values?.mobileNumber?.length) !== Number(10) ||
      values.mobileNumber === ""
    ) {
      setErrors({
        ...errors,
        mobile_number:
          values.mobileNumber === ""
            ? "Mobile Number is required"
            : Number(values?.mobileNumber?.length) !== Number(10)
            ? "Invalid Mobile Number"
            : "",
      });
      return false;
    }

    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValidate() && !btnLoading) {
      setBtnLoading(true);
      try {
        let formBody = {
          mobileNumber: values.mobileNumber,
        };
        const { data } = await authFetch.post(
          "/user/forgot-password",
          formBody
        );
        if (data && data?.status === true) {
          onSuccess(dispatch, data?.message);
          setIsSentOTP(true);
          setIsUpdatedNumber(true);
        } else {
          onFailedMsg(dispatch, data?.error);
          setIsSentOTP(false);
        }
      } catch (e) {
        onFailedMsg(dispatch, e?.response?.data?.error);
        setIsSentOTP(false);
      } finally {
        setBtnLoading(false);
      }
    }
  };

  return (
    <main className="login_main_box">
      <div className="container-fluid">
        <div className="row no-gutter">
          <div className="col-lg-6 col-sm-12 col-md-12 col-12  bg-imagae d-none d-lg-flex">
            <div className="logo-box ">
              <BgImage />
            </div>
          </div>

          <div className="col-lg-6 col-sm-12 col-md-12 col-12 bg-light">
            <div className="login d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-sm-12 col-md-11 col-11  mx-auto">
                    <img
                      className=" mobile_logo"
                      src="/assets/images/logo1.png"
                      alt="logo-img"
                      width="256"
                    />
                    <h5 className="logi-font-bod">Forgot Password</h5>
                    <h2 className="logi-font-box">welcome back!</h2>
                    <p className="logi-font-box-a mb-4">
                      {" "}
                      Enter the mobile phone number associated with your Bhrigu
                      Jyotis account.
                    </p>
                    <form className="" autoComplete="off" onSubmit={onSubmit}>
                      <div className="form-floating mb-3">
                        <PhoneInput
                          disabled={isUpdated}
                          inputProps={{
                            name: "phone",
                            required: true,
                            id: "mobileNum",
                          }}
                          disableDropdown={true}
                          countryCodeEditable={false}
                          country={"in"}
                          preferredCountries={["in"]}
                          // value={values?.contactDetails?.formattedValue}
                          onChange={handleChangeMobile}
                        />
                        {isSentOTP && (
                          <span className="eye-password">
                            <TiPencil
                              cursor={"pointer"}
                              size={18}
                              onClick={(e) => setIsUpdatedNumber(false)}
                            />
                          </span>
                        )}

                        {errors?.mobile_number && (
                          <Error>{errors?.mobile_number}</Error>
                        )}
                        {/* <label htmlFor="floatingPassword">Mobile Number</label> */}
                      </div>

                      <div className="text-right">
                        {!isSentOTP && (
                          <Button
                            type="submit"
                            isDisabled={btnLoading}
                            className="btn btn-primary butt-nox btn-block text-uppercase mb-2  shadow-sm"
                          >
                            {btnLoading ? "Loading..." : "GET OTP"} &nbsp;
                          </Button>
                        )}
                      </div>
                    </form>
                    {isSentOTP && (
                      <>
                        <VerifyOTP userData={values} />
                      </>
                    )}
                    <div className="account__switch">
                      <Link to="/login" className="mt-3">
                        {" "}
                        <b>
                          {" "}
                          Back to&nbsp;
                          <span>Login</span>
                        </b>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgetPassword;
