import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AstrologyBanner } from "../Components/UI";
import {
  DeleteConfirmationModal,
  KundliUsersModal,
  SelectLanguageModal,
} from "../models/index";
import { getKundliUsersList } from "../store/kundliUser/kundliUser-action";
import useAuthApp from "../hooks/useAuthApp";
import { onFailedMsg } from "../helper/showHideAlert";

const Dashboard = () => {
  const { dispatch, authFetchGET } = useAuthApp();
  const { kundliUsers } = useSelector((state) => state.kundliUser);
  const [isOpenModal, setIsOpenModal] = useState({ isOpen: false, data: {} });
  const [selectedUsermale, setselectedUsermale] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(kundliUsers);
  const [selectedUserFemale, setselectedUserFemale] = useState({});
  const [openLanguageModal, setOpenLanguageModal] = useState({
    isOpen: false,
    data: {},
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    isOpen: false,
    id: "",
    userName: "",
  });

  useEffect(() => {
    dispatch(getKundliUsersList(authFetchGET));
  }, []);

  const deleteKundliUsers = (id, userName) => {
    setOpenDeleteModal({ isOpen: true, id: id, userName: userName });
  };

  const editKundliUser = (id, body) => {
    setIsOpenModal({ isOpen: true, data: body });
  };

  const createKundli = (body) => {
    setOpenLanguageModal({ isOpen: true, data: body });
  };

  const createMatchingKundli = () => {
    if (
      !isEmptyObject(selectedUsermale) &&
      !isEmptyObject(selectedUserFemale)
    ) {
      const body = {
        profile_id: 335848,
        f_name: selectedUserFemale?.nameu,
        f_birth: selectedUserFemale?.birth,
        f_day: selectedUserFemale?.day,
        f_month: selectedUserFemale?.month,
        f_year: selectedUserFemale?.year,
        f_hour: selectedUserFemale?.hour,
        f_min: selectedUserFemale?.min,
        f_lat: selectedUserFemale?.lat,
        f_lon: selectedUserFemale?.lon,
        f_tzone: selectedUserFemale?.tzone,
        m_name: selectedUsermale?.nameu,
        m_birth: selectedUsermale?.birth,
        m_day: selectedUsermale?.day,
        m_month: selectedUsermale?.month,
        m_year: selectedUsermale?.year,
        m_hour: selectedUsermale?.hour,
        m_min: selectedUsermale?.min,
        m_lat: selectedUsermale?.lat,
        m_lon: selectedUsermale?.lon,
        m_tzone: selectedUsermale?.tzone,
      };
      setOpenLanguageModal({ isOpen: true, data: body });
    } else {
      onFailedMsg(dispatch, "Please Select User");
    }
  };

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  useEffect(() => {
    const filteredUsers = kundliUsers.filter(
      (user) =>
        user.nameu.toLowerCase().includes(searchText.toLowerCase()) ||
        user.birth.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredUsers(filteredUsers);
  }, [kundliUsers, searchText]);

  return (
    <main className="main_content">
      <div className="al-breadcrumb-wrapper text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="al-breadcrumb-container">
                <h1>Dashboard</h1>
                <ul>
                  <li>
                    <Link to="#">Home</Link>
                  </li>
                  <li>Dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AstrologyBanner />

      <section className="vedic_content">
        <div className="container">
          <div className="row">
            <div className="col-xl-7  col-12 m-0 p-2">
              <div className="vediv_user">
                <div className="heding_box">
                  <h2> PROFILES </h2>
                  <img src="/assets/images/1-2-1.png" alt="heding" />
                  <p> Your vedic profile list</p>
                </div>
                <div className="serch_box">
                  <input
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    type="text"
                    id="filter"
                    placeholder="Search by name and/or location"
                    maxLength={30}
                  />
                  <img
                    src="/assets/images/search.svg"
                    alt="icon"
                    className="icon"
                  />
                </div>
                <ul className="user-list">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <li key={user._id}>
                        <div className="user_card">
                          <img src="/assets/images/user.svg" alt="Edward" />
                          <div className="user-info">
                            <h4>{user.nameu}</h4>
                            <p>{user.birth}</p>
                          </div>
                        </div>
                        <div className="icon_box">
                          <img
                            className="cursor-pointer"
                            src="/assets/images/delete.svg"
                            alt="icon"
                            onClick={() =>
                              deleteKundliUsers(user._id, user.nameu)
                            }
                          />
                          <img
                            className="cursor-pointer"
                            src="/assets/images/edit.svg"
                            alt="icon"
                            onClick={() => editKundliUser(user._id, user)}
                          />
                          <Link
                            to="#"
                            className="but_box"
                            onClick={() => createKundli(user)}
                          >
                            Kundli
                          </Link>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="box_not_text">
                      <h4>No Profiles!</h4>
                    </div>
                  )}
                </ul>

                <div
                  className="carete_new_butt"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={(e) => setIsOpenModal({ isOpen: true, data: {} })}
                >
                  <p>Create New Profile</p>
                </div>
              </div>
            </div>
            <div className="col-xl-5  col-12 m-0 p-2">
              <div className="maching_ditale">
                <div className="heding_box">
                  <h2> MATCH MAKING </h2>
                  <img src="/assets/images/1-2-1.png" alt="heding" />
                  <p> Your vedic profile list</p>
                </div>
                <div className="mach_box">
                  <h2>
                    {" "}
                    <img src="/assets/images/user.svg" alt="Edward" /> select
                    male profile
                  </h2>
                  <div className="section_ser">
                    <select
                      name="role"
                      className="form-control"
                      required=""
                      value={selectedUsermale?.nameu}
                      onChange={(event) => {
                        const selectedUsermale = kundliUsers.find(
                          (user) => user.nameu === event.target.value
                        );
                        setselectedUsermale(selectedUsermale);
                      }}
                    >
                      <option value="">Select</option>

                      {kundliUsers.map((user) => {
                        if (user.gender === "male") {
                          return (
                            <option key={user._id} value={user.nameu}>
                              {user.nameu}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                  </div>
                  <div className="birt_box">
                    <input
                      type="text"
                      name="name"
                      placeholder="Birth Date"
                      className="form-control"
                      readOnly
                      value={
                        (selectedUsermale?.day ||
                          selectedUsermale?.month ||
                          selectedUsermale?.year) === undefined
                          ? "Birth Date"
                          : `${selectedUsermale?.day}/${selectedUsermale?.month}/${selectedUsermale?.year}`
                      }
                    />

                    <input
                      readOnly
                      type="text"
                      name="name"
                      placeholder="birth Time"
                      className="form-control"
                      value={
                        (selectedUsermale?.hour || selectedUsermale?.min) ===
                        undefined
                          ? "Birth Time"
                          : `${selectedUsermale?.hour} : ${selectedUsermale?.min}`
                      }
                    />
                  </div>
                  <div className="add_red">
                    <input
                      readOnly
                      type="text"
                      name="name"
                      placeholder="Birth Place"
                      className="form-control"
                      value={
                        selectedUsermale?.birth === undefined
                          ? "Birth Place"
                          : `${selectedUsermale?.birth}`
                      }
                    />
                  </div>
                </div>
                <div className="mach_box">
                  <h2>
                    {" "}
                    <img src="/assets/images/user.svg" alt="Edward" /> select
                    Female profile
                  </h2>
                  <div className="section_ser">
                    <select
                      name="role"
                      className="form-control"
                      required=""
                      value={selectedUserFemale?.nameu}
                      onChange={(event) => {
                        const selectedUserFemale = kundliUsers.find(
                          (user) => user.nameu === event.target.value
                        );
                        setselectedUserFemale(selectedUserFemale);
                      }}
                    >
                      <option value="">Select</option>

                      {kundliUsers.map((user) => {
                        if (user.gender === "female") {
                          return (
                            <option key={user._id} value={user.nameu}>
                              {user.nameu}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                  </div>
                  <div className="birt_box">
                    <input
                      type="text"
                      name="name"
                      placeholder="birth Date"
                      className="form-control"
                      readOnly
                      value={
                        (selectedUserFemale?.day ||
                          selectedUserFemale?.month ||
                          selectedUserFemale?.year) === undefined
                          ? "Birth Date"
                          : `${selectedUserFemale?.day}/${selectedUserFemale?.month}/${selectedUserFemale?.year}`
                      }
                    />
                    <input
                      type="text"
                      name="name"
                      placeholder="birth Time"
                      className="form-control"
                      readOnly
                      value={
                        (selectedUserFemale?.hour ||
                          selectedUserFemale?.min) === undefined
                          ? "Birth Time"
                          : `${selectedUserFemale?.hour} : ${selectedUserFemale?.min}`
                      }
                    />
                  </div>
                  <div className="add_red">
                    <input
                      type="text"
                      name="name"
                      placeholder="Birth Place"
                      className="form-control"
                      readOnly
                      value={
                        selectedUserFemale?.birth === undefined
                          ? "Birth Place"
                          : `${selectedUserFemale?.birth}`
                      }
                    />
                  </div>
                </div>

                <div
                  className="carete_new_butt"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => createMatchingKundli()}
                >
                  <p>Match Kundli</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpenModal?.isOpen && (
        <KundliUsersModal
          data={isOpenModal?.data}
          isOpen={isOpenModal?.isOpen}
          onHideHandler={(e) => setIsOpenModal({})}
        />
      )}
      {openLanguageModal.isOpen && (
        <SelectLanguageModal
          data={openLanguageModal?.data}
          isOpen={openLanguageModal?.isOpen}
          onHideHandler={(e) => setOpenLanguageModal({})}
        />
      )}
      {openDeleteModal.isOpen && (
        <DeleteConfirmationModal
          userName={openDeleteModal.userName}
          isOpen={openDeleteModal?.isOpen}
          id={openDeleteModal.id}
          onHide={() => setOpenDeleteModal({ isOpen: false, id: "" })}
        />
      )}
    </main>
  );
};

export default Dashboard;
