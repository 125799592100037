import React from "react";
import { Link } from "react-router-dom";
import { AppLogo } from "../../Components/UI";

const LandingHeader = ({ scrollToAbout, scrollToServices, scrollToHome }) => {
  return (
    <header className="main-header">
      <div className="topbar">
        <div className="d-flex">
          <AppLogo />
        </div>

        {/* <ul className="menu_bar_box collapse" id="navbarToggleExternalContent">
          <li>
            <Link to="#" onClick={scrollToHome}>
              Home{" "}
            </Link>
          </li>
          <li>
            <Link to="#" onClick={scrollToAbout}>
              About Us{" "}
            </Link>
          </li>
          <li>
            <Link to="#" onClick={scrollToServices}>
              Services{" "}
            </Link>
          </li>
          <li>
            <Link to="#">Contact US</Link>
          </li>
        </ul> */}

        <div className="user-noti-box">
          <div className="login_card">
            <Link to="/login" className="loin_box_link">
              {/* <img src="/assets/images/add_uaer.svg" alt="icon" width="20" />{" "} */}
              Log in{" "}
            </Link>
            {/* <span> / </span> */}
            <Link to="/register" className="loin_box_link" >
              {" "}
              Register
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default LandingHeader;
