import React from "react";

const AstroProfile = ({ kundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <div className="col-lg-12 col-12 m-0 p-0 px-1">
        <h3 className="heding_bx">Basic Details</h3>
        <div className="kundli_name row">
          <p className="col-lg-3 col-12">
            Date of Birth : {kundli?.basicDetails?.dob}
          </p>
          <p className="col-lg-3 col-12">
            Time of Birth : {kundli?.basicDetails?.timeofbirth}
          </p>
          <p className="col-lg-3 col-12">
            Place of Birth : {kundli?.basicDetails?.birth}
          </p>
          <p className="col-lg-3 col-12">
            Latitude : {kundli?.basicDetails?.lat}
          </p>
          <p className="col-lg-3 col-12">
            Longtitude : {kundli?.basicDetails?.lon}
          </p>
          <p className="col-lg-3 col-12">
            Time Zone : {kundli?.basicDetails?.tzone}
          </p>
          <p className="col-lg-3 col-12">
            Sunrise : {kundli["basic_panchang/sunrise"]?.sunrise}
          </p>
          <p className="col-lg-3 col-12">
            Sunset : {kundli["basic_panchang/sunrise"]?.sunset}
          </p>
        </div>
      </div>
      <div className="col-lg-12 col-12 m-0 p-0 px-1">
        <h3 className="heding_bx">Panchang Details</h3>
        <div className="kundli_name">
          <p className="col-lg-3 col-12">
            Tithi : {kundli["basic_panchang/sunrise"]?.tithi}
          </p>
          <p className="col-lg-3 col-12">
            Yog : {kundli["basic_panchang/sunrise"]?.yog}
          </p>
          <p className="col-lg-3 col-12">
            Nakshatra : {kundli["basic_panchang/sunrise"]?.nakshatra}
          </p>
          <p className="col-lg-3 col-12">
            Karan : {kundli["basic_panchang/sunrise"]?.karan}
          </p>
        </div>
      </div>

      <div className="col-lg-12 col-12 m-0 p-0 px-1">
        <h3 className="heding_bx">Astrological Details</h3>
        <div className="kundli_name">
          <p className="col-lg-3 col-12">
            Varna : {kundli?.astro_details?.Varna}
          </p>
          <p className="col-lg-3 col-12">
            Vashya : {kundli?.astro_details?.Vashya}
          </p>
          <p className="col-lg-3 col-12">
            Yoni : {kundli?.astro_details?.Yoni}
          </p>
          <p className="col-lg-3 col-12">Gan : {kundli?.astro_details?.Gan}</p>
          <p className="col-lg-3 col-12">
            Nadi : {kundli?.astro_details?.Nadi}
          </p>
          <p className="col-lg-3 col-12">
            Sign : {kundli?.astro_details?.sign}
          </p>
          <p className="col-lg-3 col-12">
            Sign Lord : {kundli?.astro_details?.SignLord}
          </p>
          <p className="col-lg-3 col-12">
            Nakshatra : {kundli?.astro_details?.Naksahtra}
          </p>
          <p className="col-lg-3 col-12">
            Nakshatra Lord : {kundli?.astro_details?.NaksahtraLord}
          </p>
          <p className="col-lg-3 col-12">
            Charan : {kundli?.astro_details?.Charan}
          </p>
          <p className="col-lg-3 col-12">
            Yunja : {kundli?.astro_details?.yunja}
          </p>
          <p className="col-lg-3 col-12">
            Tatva : {kundli?.astro_details?.tatva}
          </p>
          <p className="col-lg-3 col-12">
            Name Alphabet : {kundli?.astro_details?.name_alphabet}
          </p>
          <p className="col-lg-3 col-12">
            Paya : {kundli?.astro_details?.paya}
          </p>
          <p className="col-lg-3 col-12">
            Ascendant : {kundli?.astro_details?.ascendant}
          </p>
          <p className="col-lg-3 col-12">
            Ascendant Lord : {kundli?.astro_details?.ascendant_lord}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AstroProfile;
