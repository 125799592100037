import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import useAuthApp from "../hooks/useAuthApp";
import { zodiacDetails } from "../store/zodiac/zodiac-action";
import { useSelector } from "react-redux";
import { zodiacAction } from "../store/zodiac/zodiac-slice";
import { AnimatedSpinner } from "../Components/UI/index";

const ZodiacPopup = ({ isOpen, onHide, selectedZodiac }) => {
  const { zodiac } = useSelector((state) => state.zodiac);
  const { authFetchGET, dispatch } = useAuthApp();
  const [activeTab, setActiveTab] = useState(2);
  const [loading, setLoading] = useState(false);
  const [Zodiac, setZodiac] = useState(selectedZodiac);

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const handleZodiacSelect = (zodiac) => {
    setZodiac(zodiac);
  };

  const onClose = () => {
    dispatch(zodiacAction.onEmptyZodiacData());
    onHide();
  };

  useEffect(() => {
    const getDetails = async () => {
      const body = {
        type: "DAILY",
        zodiac: Zodiac,
        languageId: activeTab,
        time: Date.now().toString(),
        timezone: "Asia/Kolkata",
      };
      setLoading(true);
      await dispatch(zodiacDetails(authFetchGET, body));
      setLoading(false);
    };
    getDetails();
  }, [activeTab, Zodiac]);

  return (
    <>
      <Modal
        size="md"
        show={isOpen}
        className="model_card_main"
        onHide={onClose}
        centered
      >
        <Modal.Header closeButton className="m-0 p-2 kundli_language">
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabChange}
            className="language_box"
          >
            <Tab eventKey={1} title="English"></Tab>
            <Tab eventKey={2} title="Hindi"></Tab>
          </Tabs>
          <Dropdown onSelect={handleZodiacSelect}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {Zodiac}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="Aries">Aries</Dropdown.Item>
              <Dropdown.Item eventKey="Taurus">Taurus</Dropdown.Item>
              <Dropdown.Item eventKey="Gemini">Gemini</Dropdown.Item>
              <Dropdown.Item eventKey="Cancer">Cancer</Dropdown.Item>
              <Dropdown.Item eventKey="Leo">Leo</Dropdown.Item>
              <Dropdown.Item eventKey="Virgo">Virgo</Dropdown.Item>
              <Dropdown.Item eventKey="Libra">Libra</Dropdown.Item>
              <Dropdown.Item eventKey="Scorpio">Scorpio</Dropdown.Item>
              <Dropdown.Item eventKey="Sagittarius">Sagittarius</Dropdown.Item>
              <Dropdown.Item eventKey="Capricorn">Capricorn</Dropdown.Item>
              <Dropdown.Item eventKey="Aquarius">Aquarius</Dropdown.Item>
              <Dropdown.Item eventKey="Pisces">Pisces</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center position-relative">
              <AnimatedSpinner />
            </div>
          ) : zodiac ? (
            <div className="kundli_language">
              <div>
                <h5 className="heding_bx">Profession</h5>
                <p className="kundli_basi_p ">
                  {" "}
                  <div
                    dangerouslySetInnerHTML={{ __html: zodiac?.profession }}
                  />
                </p>
              </div>
              <div>
                <h5 className="heding_bx">Love</h5>
                <p className="kundli_basi_p ">
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: zodiac?.love }} />
                </p>
              </div>
              <div>
                <h5 className="heding_bx">Luck</h5>
                <p className="kundli_basi_p ">
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: zodiac?.luck }} />
                </p>
              </div>
              <div>
                <h5 className="heding_bx">Emotions</h5>
                <p className="kundli_basi_p ">
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: zodiac?.emotions }} />
                </p>
              </div>
              <div>
                <h5 className="heding_bx">Health</h5>
                <p className="kundli_basi_p ">
                  {" "}
                  <div dangerouslySetInnerHTML={{ __html: zodiac?.health }} />
                </p>
              </div>
              <div>
                <h5 className="heding_bx">travel</h5>
                <p className="kundli_basi_p ">
                  <div dangerouslySetInnerHTML={{ __html: zodiac?.travel }} />
                </p>
              </div>
            </div>
          ) : (
            <h5>Please try again.</h5>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ZodiacPopup;
