import React from "react";

const ManglikDosha = ({ kundli }) => {
  return (
    <div className="kundli_basi row  m-0">
      <h4 className="heding_bx">What is Manglik Dosha ?</h4>
      <p className="mt-3">
        The Manglik Dosha is known to be very inauspicious and the individual
        may remain unmarried throughout his life. People are generally fearful
        towards this negative result of the planet that could effect their life.
        Especially, marriage. The Vedic astrology considers ascendent and the
        placement of 4th, 2nd, 7th, 8th and 12th house as unfavourable. Mars is
        basically known as the killer of marriage in all these houses as it is a
        significator of an individual’s health, personality and physic. An
        individual of Mars has the lack of politeness as their trait.
      </p>
      <div className="box_card">
        <h3>{kundli?.manglik?.manglik_status}</h3>
        <h3>{kundli?.manglik?.percentage_manglik_present}</h3>
        <h3>{kundli?.manglik?.manglik_report}</h3>
      </div>
      <div>
        <h4 className="heding_bx">Remedies for Manglik Dosha</h4>
        <br />
        <ul className=" box_card">
          <li>
            Install an energized Mangal Yantra in your place of worship.
            Meditate on the triangular Mangal Yantra along with the recitation
            of Mangal mantra: Om Kram Krim Krom Sah Bhomayay Namah
          </li>
          <li>
            In the evening, visit a Hanuman temple draw a triangle with red
            kumkum (roli) on a plate and worship Hanumanji with sindoor or red
            sandalwood, red flowers and a lighted lamp.
          </li>
          <li>
            Worship Lord Hanuman with the mantra: '||OM SHREEM HANUMATE NAMAH||'
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ManglikDosha;
