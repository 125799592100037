import { onRememberMeHandler } from "./presist-slice";

export const onRememberMeHandlerMd = (isChecked, obj) => {
  return (dispatch) => {
    if (isChecked) {
      dispatch(
        onRememberMeHandler({
          ...obj,
        })
      );
    } else {
      dispatch(
        onRememberMeHandler({
          flag: false,
          mobileNumber: "",
          password: "",
        })
      );
    }
  };
};
