import { createSlice } from "@reduxjs/toolkit";

const kundliUserSlice = createSlice({
  name: "kundliUser",
  initialState: {
    kundliUsers: [],
  },
  reducers: {
    updateKundliUsers(state, action) {
      state.kundliUsers = action.payload.kundliUsers;
    },
  },
});

export const kundliUserActions = kundliUserSlice.actions;
export default kundliUserSlice;
