import { useState } from "react";
import { changePasswordHandler } from "../store/auth/auth-action";
import { Error, ShowHidePwd, Button } from "../Components/UI";
import useAuthApp from "../hooks/useAuthApp";
import Modal from "react-bootstrap/Modal";

const InputBox = ({
  autoFocus,
  name,
  placeholder,
  value,
  onChange,
  isShowPwd,
  title,
  id,
  ...props
}) => {
  return (
    <input
      autoFocus={autoFocus ? true : false}
      type={`${isShowPwd ? "password" : "text"}`}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength={25}
      title={title}
      id={id}
      {...props}
    />
  );
};

const ChnagePasswordModal = ({ isOpen, onHide }) => {
  const { dispatch, navigate, authFetch } = useAuthApp();
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isShowPwd, setIsShowPwd] = useState({
    old: true,
    new: true,
    confirm: true,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  let newPassword = password.newPassword;
  let confirmPassword = password.confirmPassword;
  let oldPassword = password.oldPassword;
  // let check = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,30}/.test(newPassword);

  const onValueChangeHandler = (e) => {
    setPassword((previousState) => {
      return {
        ...previousState,
        [e.target.name]:
          e.target.value?.length <= 25
            ? e.target.value
            : previousState[e.target.name],
      };
    });
    setErrors((previousState) => {
      return {
        ...previousState,
        [e.target.name]:
          e.target.value === "" ? `${e.target.title} is required` : "",
      };
    });
  };

  const isValidateForm = () => {
    if (
      newPassword === "" ||
      confirmPassword === "" ||
      confirmPassword !== newPassword
    ) {
      setErrors((previousState) => {
        return {
          ...previousState,
          oldPassword: oldPassword === "" ? "Old Password is required" : "",
          newPassword: newPassword === "" ? "New Password is required" : "",
          confirmPassword:
            confirmPassword === ""
              ? "Confirm Password is required"
              : confirmPassword !== newPassword
              ? "Password Should be match"
              : "",
        };
      });
      return false;
    }
    return true;
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (isValidateForm() && !loading) {
      let data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      setLoading(true);
      let res = await dispatch(
        changePasswordHandler(data, navigate, authFetch)
      );
      if (res) {
        setLoading(false);
      }
    }
  };
  const onTogglePassword = (type) => {
    setIsShowPwd((pre) => ({
      ...pre,
      [type]: !pre[type],
    }));
  };

  return (
    <Modal
      size="md"
      show={isOpen}
      className="model_card_main"
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton className="modal-header m-0 p-3">
        <h6>Set New Password</h6>
      </Modal.Header>

      <div className="modal-body ">
        <form
          autoComplete="off"
          onSubmit={onSubmitHandler}
          className="account__form paswword_box form-wrap"
        >
          <div className="form-group">
            <label htmlFor="newPassword" className="form-label">
              Old Password
            </label>
            <InputBox
              className="form-control"
              name="oldPassword"
              id="oldPassword"
              isShowPwd={isShowPwd?.old}
              title="Old Password"
              placeholder="Old Password"
              value={password.oldPassword}
              onChange={onValueChangeHandler}
            />
            <ShowHidePwd
              isShowPwd={isShowPwd?.old}
              onTogglePassword={() => onTogglePassword("old")}
            />
            {errors?.oldPassword && <Error>{errors?.oldPassword}</Error>}
          </div>

          <div className="form-group">
            <label htmlFor="newPassword" className="form-label">
              New Password
            </label>
            <InputBox
              className="form-control"
              name="newPassword"
              id="newPassword"
              isShowPwd={isShowPwd?.new}
              title="New Password"
              placeholder="New Password"
              value={password.newPassword}
              onChange={onValueChangeHandler}
            />
            <ShowHidePwd
              isShowPwd={isShowPwd?.new}
              onTogglePassword={() => onTogglePassword("new")}
            />
            {errors?.newPassword && <Error>{errors?.newPassword}</Error>}
          </div>

          <div className="form-group">
            <label htmlFor="confirmPassword" className="form-label">
              Confirm Password
            </label>
            <InputBox
              className="form-control"
              isShowPwd={isShowPwd?.confirm}
              name="confirmPassword"
              id="confirmPassword"
              placeholder="Confirm Password"
              title="Confirm Password"
              value={password.confirmPassword}
              onChange={onValueChangeHandler}
            />
            <ShowHidePwd
              isShowPwd={isShowPwd?.confirm}
              onTogglePassword={() => onTogglePassword("confirm")}
            />
            {errors?.confirmPassword && (
              <Error>{errors?.confirmPassword}</Error>
            )}
          </div>

          <div className="new_pass_but">
            {/* <Button
              type="button"
              classes="btn btn-secondary trk-btn-box"
              onClick={() =>
                dispatch(authAction.onToggleResetPasswordModalStatus(false))
              }
            >
              Set Later
            </Button> */}

            <Button
              type="submit"
              isDisabled={loading}
              className="btn save_butt"
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default ChnagePasswordModal;
