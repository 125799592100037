import { createSlice } from "@reduxjs/toolkit";

const zodiacSlice = createSlice({
  name: "zodiac",
  initialState: {
    zodiac: null,
  },
  reducers: {
    updateZodiac(state, action) {
      state.zodiac = action.payload.zodiac;
    },
    onEmptyZodiacData(state, action) {
      state.zodiac = null;
    },
  },
});

export const zodiacAction = zodiacSlice.actions;
export default zodiacSlice;
