import { createSlice } from "@reduxjs/toolkit";

const presistSlice = createSlice({
  name: "presister",
  initialState: {
    isRememberMe: {
      flag: false,
      mobileNumber: "",
      password: "",
    },
  },
  reducers: {
    onRememberMeHandler(state, action) {
      state.isRememberMe = action.payload;
    },
  },
});

export const { onRememberMeHandler } = presistSlice.actions;
export default presistSlice;
