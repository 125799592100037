import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    token: localStorage.getItem("token") || undefined,
    resetPasswordModalStatus: false,
  },
  reducers: {
    onLoginRequest(state) {
      state.loading = true;
    },
    onUpdateUserToken(state, action) {
      state.token = action.payload;
    },
    onLoginSuccess(state, action) {
      state.loading = false;
      state.isAuthenticated = true;
      state.user = action.payload.data;
      state.token = action.payload.data.token;
    },
    onLoginFailed(state) {
      state.loading = false;
    },
    onSetLogoutHandler(state) {
      state.token = undefined;
    },
    onToggleResetPasswordModalStatus(state, action) {
      state.resetPasswordModalStatus = action.payload;
    },
  },
});

export const authAction = authSlice.actions;
export default authSlice;
