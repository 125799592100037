import React from "react";

const RudrakshaSuggestion = ({ kundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <div>
        <img
          src={`https://vedicrishi.in/rudraksha/${kundli?.rudraksha_suggestion?.img_url
            .split("/")
            .pop()}`}
        />
        <h4>{kundli?.rudraksha_suggestion?.name}</h4>
      </div>
      <div>
        <h6>{kundli?.rudraksha_suggestion?.recommend}</h6>
        <p>{kundli?.rudraksha_suggestion?.detail}</p>
      </div>
    </div>
  );
};

export default RudrakshaSuggestion;
