import { useState } from "react";
import Slider from "react-slick";
import { ZodiacPopup } from "../../models";

const AstrologyBanner = () => {
  const [selectedZodiac, setSelectedZodiac] = useState("");
  const [openZodiacPopup, setOpenZodiacPopup] = useState({
    isOpen: false,
  });

  const getZodiacDetails = (zodiac) => {
    setSelectedZodiac(zodiac);
    setOpenZodiacPopup({ isOpen: true });
  };

  const settings = {
    slidesToShow: 10,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  return (
    <section className="w-100 ">
      <div className="container ">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <Slider {...settings} className="kundali_content m-0 mt-5">
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Libra")}
              >
                <span className="icon_image">
                  <img src="/assets/images/g.svg" alt="icon" />
                </span>
                <p>Libra</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Aries")}
              >
                <span className="icon_image">
                  <img src="/assets/images/b.svg" alt="icon" />
                </span>
                <p>Aries</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Taurus")}
              >
                <span className="icon_image">
                  <img src="/assets/images/l.svg" alt="icon" />
                </span>
                <p>Taurus</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Gemini")}
              >
                <span className="icon_image">
                  <img src="/assets/images/e.svg" alt="icon" />
                </span>
                <p>Gemini</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Cancer")}
              >
                <span className="icon_image">
                  <img src="/assets/images/c.svg" alt="icon" />
                </span>
                <p>Cancer</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Leo")}
              >
                <span className="icon_image">
                  <img src="/assets/images/f.svg" alt="icon" />
                </span>
                <p>Leo</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Virgo")}
              >
                <span className="icon_image">
                  <img src="/assets/images/m.svg" alt="icon" />
                </span>
                <p>Virgo</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Sagittarius")}
              >
                <span className="icon_image">
                  <img src="/assets/images/j.svg" alt="icon" />
                </span>
                <p>Sagittarius</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Capricorn")}
              >
                <span className="icon_image">
                  <img src="/assets/images/d.svg" alt="icon" />
                </span>
                <p>Capricorn</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Aquarius")}
              >
                <span className="icon_image">
                  <img src="/assets/images/a.svg" alt="icon" />
                </span>
                <p>Aquarius</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Pisces")}
              >
                <span className="icon_image">
                  <img src="/assets/images/h.svg" alt="icon" />
                </span>
                <p>Pisces</p>
              </div>
              <div
                className="kudalli_box cursor-pointer"
                onClick={() => getZodiacDetails("Scorpio")}
              >
                <span className="icon_image">
                  <img src="/assets/images/k.svg" alt="icon" />
                </span>

                <p>Scorpio</p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      {openZodiacPopup.isOpen && (
        <ZodiacPopup
          selectedZodiac={selectedZodiac}
          isOpen={openZodiacPopup.isOpen}
          onHide={() => setOpenZodiacPopup({ isOpen: false })}
        />
      )}
    </section>
  );
};

export default AstrologyBanner;
