import { onFailedMsg } from "../../helper/showHideAlert";
import { kundliActions } from "./kundli-slice";

export const createKundli = (authFetch, navigate, body) => {
  return async (dispatch) => {
    try {
      let { data } = await authFetch.post(`/kundali/create-kundali`, body);
      if (data && data?.status === true) {
        dispatch(kundliActions.updateKundli({ kundli: data.data }));
        localStorage.setItem("firstTime", true);
        navigate("/kundli", { state: body });
      } else {
        onFailedMsg(dispatch, data?.error);
      }
    } catch (error) {
      console.log("erro", error);
      onFailedMsg(dispatch, error?.response?.data?.error);
    }
  };
};
