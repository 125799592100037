import React from "react";

const BirthDetails = ({ matchingKundli }) => {
  return (
    <div className="kundli_basi row  m-0">
      <div className="reposive_teble ">
        <table className="w-100">
          <thead>
            <tr>
              <th>Male</th>
              <th>Birth Details</th>
              <th>Female</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.male_astro_details?.name
                }
              </td>
              <td>Name</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.female_astro_details?.name
                }
              </td>
            </tr>
            <tr>
              <td>{`${matchingKundli?.callAllMatch?.birth_details?.male_astro_details?.day} / ${matchingKundli?.callAllMatch?.birth_details?.male_astro_details?.month} / ${matchingKundli?.callAllMatch?.birth_details?.male_astro_details?.year}`}</td>
              <td>Birth Date</td>
              <td>
                {`${matchingKundli?.callAllMatch?.birth_details?.female_astro_details?.day} / ${matchingKundli?.callAllMatch?.birth_details?.female_astro_details?.month} / ${matchingKundli?.callAllMatch?.birth_details?.female_astro_details?.year}`}
              </td>
            </tr>
            <tr>
              <td>{`${matchingKundli?.callAllMatch?.birth_details?.male_astro_details?.hour} : ${matchingKundli?.callAllMatch?.birth_details?.male_astro_details?.minute} `}</td>
              <td>Birth Time</td>
              <td>
                {`${matchingKundli?.callAllMatch?.birth_details?.female_astro_details?.hour} : ${matchingKundli?.callAllMatch?.birth_details?.female_astro_details?.minute}`}
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.male_astro_details?.latitude
                }
              </td>
              <td>Latitude</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.female_astro_details?.latitude
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.male_astro_details?.longitude
                }
              </td>
              <td>Longitude</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.female_astro_details?.longitude
                }
              </td>
            </tr>
            <tr>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.male_astro_details?.timezone
                }
              </td>
              <td>Time Zone</td>
              <td>
                {
                  matchingKundli?.callAllMatch?.birth_details
                    ?.female_astro_details?.timezone
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BirthDetails;
