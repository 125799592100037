import React, { useState } from "react";

const Charts = ({ matchingKundli }) => {
  const [chart, setChart] = useState("_lagna_birth_chart");
  const [mode, setMode] = useState("male");

  const handleChartChange = (event) => {
    setChart(event.target.value);
  };

  return (
    <div className="kundli_basi row justify-content-center m-0">
      <div className="col-lg-4 col-12 my-3">
        <select
          className="form-control"
          value={chart}
          onChange={handleChartChange}
        >
          <option value="_lagna_birth_chart">Birth Chart</option>
          <option value="_chalit_chart">Chalit Chart</option>
          <option value="_moon_chart">Moon Chart</option>
          <option value="_navmansha_chart">Navmansha Chart</option>
        </select>

        <div className="my-2 d-flex justify-content-center">
          <button
            className={`btn btn-primary mx-1 ${
              mode === "male" ? "active" : ""
            }`}
            onClick={() => setMode("male")}
          >
            Male
          </button>

          <button
            className={`btn btn-primary mx-1 ${
              mode === "feMale" ? "active" : ""
            }`}
            onClick={() => setMode("feMale")}
          >
            Female
          </button>
        </div>
        <div></div>
        <div className="col-lg-12 col-12">
          <div
            className="text-center chat_box"
            dangerouslySetInnerHTML={{
              __html:
                mode === "male"
                  ? chart === "_chalit_chart"
                    ? matchingKundli[`m${chart}`]
                    : matchingKundli[`m${chart}`]?.svg
                  : chart === "_chalit_chart"
                  ? matchingKundli[`f${chart}`]
                  : matchingKundli[`f${chart}`]?.svg,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
