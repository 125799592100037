import { onLogoutHandler } from "../auth/auth-action";
import { setUserProfile } from "./user-slice";

export const onFetchUserProfileHandler = (authFetchGET, navigate) => {
  return async (dispatch) => {
    try {
      const { data } = await authFetchGET.get("/user/profile");
      console.log("data", data);
      if (data && data?.status === true) {
        dispatch(setUserProfile({ user: data?.data }));
      } else {
        dispatch(
          setUserProfile({
            user: {
              fullName: "User",
              isVerify: false,
              mobileNumber: "",
              role: "user",
              isPasswordChanged: false,
              balance: 0,
            },
          })
        );
        dispatch(onLogoutHandler(navigate));
      }
    } catch (e) {
      dispatch(
        setUserProfile({
          user: {
            fullName: "User",
            isVerify: false,
            mobileNumber: "",
            role: "user",
            isPasswordChanged: false,
            balance: 0,
          },
        })
      );
      dispatch(onLogoutHandler(navigate));
    }
  };
};
