import { authAction } from "./auth-slice";
import { onSuccess, onFailedMsg } from "../../helper/showHideAlert";
import { setUserProfile } from "../user/user-slice";

function onLogoutHandlerFun(dispatch) {
  dispatch(authAction.onSetLogoutHandler());
  dispatch(
    setUserProfile({
      user: {
        user_id: undefined,
        userName: "User",
        mobileNumber: "",
        role: "user",
      },
    })
  );
}

export const userLogoutOnSessionExpired = (navigate, msg) => {
  return (dispatch) => {
    localStorage.clear();
    onLogoutHandlerFun(dispatch);
    onFailedMsg(dispatch, msg ? msg : "Session Expired.");
    navigate("/login");
  };
};

export const changePasswordHandler = (body, navigate, authFetch) => {
  return async (dispatch) => {
    try {
      let { data } = await authFetch.post(`/user/change-password`, body);
      if (data.status === true) {
        localStorage.clear();
        dispatch(authAction.onToggleResetPasswordModalStatus(false));
        onLogoutHandlerFun(dispatch);
        onSuccess(dispatch, data?.message);
        navigate("/login");
      } else {
        onFailedMsg(dispatch, data?.error);
      }
    } catch (error) {
      onFailedMsg(
        dispatch,
        error?.response?.data?.error || "Old Password dose not match"
      );
    } finally {
      return true;
    }
  };
};

export const onLogoutHandler = (navigate, authFetchGET) => {
  return async (dispatch) => {
    try {
      const { data } = await authFetchGET.get(`/user/logout`);
      if (data && data.status === true) {
        localStorage.clear();
        onLogoutHandlerFun(dispatch);
        navigate("/landing");
        onSuccess(dispatch, data?.message);
      }
    } catch (e) {
      localStorage.clear();
      onLogoutHandlerFun(dispatch);
      navigate("/landing");
    } finally {
      return true;
    }
  };
};

export const onChangePasswordHandlerAction = (body, authFetch) => {
  return async (dispatch) => {
    try {
      const { data } = await authFetch.put(
        `/user/resetpassword/${body.id}`,
        body
      );
      if (data && data?.status === true) {
        onSuccess(dispatch, data?.message);
        return true;
      } else {
        onFailedMsg(dispatch, data?.message);
      }
    } catch (e) {
      onFailedMsg(dispatch, "Error");
    }
  };
};
