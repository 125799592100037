import React from "react";
import AuthSvg from "../svg/AuthSvg";

const BgImage = () => {
  return (
    <div>
      <AuthSvg />
      <img
        className="logo-image"
        src="/assets/images/logo_white.png"
        alt="logo-img"
        width="10"
      />
    </div>
  );
};

export default BgImage;
