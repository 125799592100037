import React from "react";

const Ashtkoota = ({ matchingKundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <div className="reposive_teble ">
        <table className="w-100">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Description</th>
              <th>Male</th>
              <th>Female</th>
              <th>Outof</th>
              <th>Received</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Varna</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.varna?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.varna
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.varna
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.varna?.total_points}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.varna
                    ?.received_points
                }
              </td>
            </tr>
            <tr>
              <td>Vashya</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.vashya?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.vashya
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.vashya
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.vashya?.total_points}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.vashya
                    ?.received_points
                }
              </td>
            </tr>
            <tr>
              <td>Tara</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.tara?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.tara
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.tara
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.tara?.total_points}
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.tara?.received_points}
              </td>
            </tr>
            <tr>
              <td>Yoni</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.yoni?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.yoni
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.yoni
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.yoni?.total_points}
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.yoni?.received_points}
              </td>
            </tr>
            <tr>
              <td>Maitri</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.maitri?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.maitri
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.maitri
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.maitri?.total_points}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.maitri
                    ?.received_points
                }
              </td>
            </tr>
            <tr>
              <td>Gan</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.gan?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.gan
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.gan
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.gan?.total_points}
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.gan?.received_points}
              </td>
            </tr>
            <tr>
              <td>Bhakut</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.bhakut?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.bhakut
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.bhakut
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.bhakut?.total_points}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.bhakut
                    ?.received_points
                }
              </td>
            </tr>
            <tr>
              <td>Nadi</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.nadi?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.nadi
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.nadi
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.nadi?.total_points}
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.nadi?.received_points}
              </td>
            </tr>
            <tr>
              <td>Total</td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.total?.description}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.total
                    ?.male_koot_attribute
                }
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.total
                    ?.female_koot_attribute
                }
              </td>
              <td>
                {matchingKundli?.callAllMatch?.ashtakoot?.total?.total_points}
              </td>
              <td>
                {
                  matchingKundli?.callAllMatch?.ashtakoot?.total
                    ?.received_points
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Ashtkoota;
