import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    modalIsVisible: false,
    alertShow: false,
    alertType: '',
    alertText: ''
  },
  reducers: {
    toggle(state) {
      state.modalIsVisible = !state.modalIsVisible;
    },
    showAlert(state, action) {
      state.alertShow = action.payload.alertShow;
      state.alertType = action.payload.alertType;
      state.alertText = action.payload.alertText;
    },
    hideAlert(state) {
      state.alertShow = false;
      state.alertType = '';
      state.alertText = '';
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;
