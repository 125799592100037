const Error = ({ children }) => {
  return (
    <span
      className="text-danger"
      style={{
        fontWeight: "700px",
        padding: "0px",
        margin: "0px",
        fontSize: "15px",
      }}
    >
      {children}
    </span>
  );
};
export default Error;
