import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const VimshottariDasha = ({ kundli }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const showSubDasha = (clickedIndex) => {
    setActiveIndex((prevState) => {
      if (activeIndex === clickedIndex) {
        return null; // Close dropdown if already open
      } else {
        return clickedIndex;
      }
    });
  };

  return (
    <div className="kundli_basi row  justify-content-center m-0">
      <div className="col-lg-12 col-12">
        <h3 className="heding_bx">Vimshottari Dasha</h3>
        <p className="mt-4">
          Vimshottari Dasha is the most logical and accurate dasha system to
          predict the event of the past, future, and present of a person's life
          such as your marital, career and health predictions. It can predict
          any event in an astrology chart. Vimshottari holds a fixed cyclic
          order planet's Mahadasha. This system works on the basis of
          Nakshatras. It starts from birth until the end of a native's life.
          Mahadasha and Antardasha are the two main categories of Vimshottari
          where Antardasha gives the more exact time whereas the first Mahadasha
          is predicted by the Nakshatra of Moon's transiting at the birth time.
        </p>
      </div>
      <div className="col-lg-12 col-12 ">
        <h4 className="heding_bx">Current Dasha</h4>

        <div className="row d-flex justify-content-center my-3">
          <div className="box_card_current col-lg-4 col-6">
            <h6>Major Dasha</h6>
            <p>Planet : {kundli?.current_vdasha?.major?.planet}</p>
            <p>Start Date : {kundli?.current_vdasha?.major?.start}</p>
            <p>End Date : {kundli?.current_vdasha?.major?.end}</p>
          </div>
          <div className="box_card_current col-lg-4 col-6">
            <h6>Antar Dasha</h6>
            <p>Planet : {kundli?.current_vdasha?.minor?.planet}</p>
            <p>Start Date : {kundli?.current_vdasha?.minor?.start}</p>
            <p>End Date : {kundli?.current_vdasha?.minor?.end}</p>
          </div>
          <div className="box_card_current col-lg-4 col-6">
            <h6>Prtyantar Dasha</h6>
            <p>Planet : {kundli?.current_vdasha?.sub_minor?.planet}</p>
            <p>Start Date : {kundli?.current_vdasha?.sub_minor?.start}</p>
            <p>End Date : {kundli?.current_vdasha?.sub_minor?.end}</p>
          </div>
          <div className="box_card_current col-lg-4 col-6">
            <h6>Sookshm Dasha</h6>
            <p>Planet : {kundli?.current_vdasha?.sub_sub_minor?.planet}</p>
            <p>Start Date : {kundli?.current_vdasha?.sub_sub_minor?.start}</p>
            <p>End Date : {kundli?.current_vdasha?.sub_sub_minor?.end}</p>
          </div>
          <div className="box_card_current col-lg-4 col-6 ">
            <h6>Pran Dasha</h6>
            <p>Planet : {kundli?.current_vdasha?.sub_sub_sub_minor?.planet}</p>
            <p>
              Start Date : {kundli?.current_vdasha?.sub_sub_sub_minor?.start}
            </p>
            <p>End Date : {kundli?.current_vdasha?.sub_sub_sub_minor?.end}</p>
          </div>
        </div>
      </div>
      <h4 className="heding_bx">Maha Dasha</h4>
      <div className="reposive_teble">
        <table className="w-100">
          <thead>
            <tr>
              <th>Planet</th>
              <th>Start Date</th>
              <th>End Date</th>
            </tr>
          </thead>
          <tbody>
            {kundli?.major_vdasha?.map((dasha, index) => (
              <React.Fragment key={index}>
                <tr
                  className="cursor-pointer"
                  onClick={() => showSubDasha(index)}
                >
                  <td>{dasha.planet}</td>
                  <td> {dasha.start}</td>
                  <td> {dasha.end}</td>
                </tr>
                {activeIndex === index && (
                  <tr>
                    <td colSpan="3" className="m-0 p-0">
                      <Dropdown className="in_td_box">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>Planet</th>
                              <th>Start Date</th>
                              <th>End Date </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dasha?.dashas.map((subDasha, subIndex) => (
                              <tr key={subIndex}>
                                <td>{subDasha?.planet}</td>
                                <td> {subDasha?.start}</td>
                                <td> {subDasha?.end} </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Dropdown>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VimshottariDasha;
