import React from "react";

const KalsarpaDosha = ({ kundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <h4 className="heding_bx">What is Kalasarpa Dosha ?</h4>
      <p className="mt-3">
        If all the 7 planets are situated between Rahu and Ketu then Kaal Sarp
        Yog is formed. According to the situation of Rahu in 12 houses of
        horoscope there are Kaal Sarp Yogas of 12 types. These are : Anant,
        Kulik, Vasuki, Shankhpal, Padma, Mahapadma, Takshak, Karkotak,
        Shankhchud, Ghaatak, Vishdhar Sheshnag. The Kaal Sarp Yog is of two
        types- Ascending and Descending. If all the 7 planets are eaten away by
        Rahu's mouth then it is Ascending Kaal Sarp Yog. If all planets are
        situated in back of Rahu then Descending Kaal Sarp Yog is formed.
      </p>
      <h3
        className="p-2 text-light"
        style={{
          backgroundColor: `${
            kundli?.kalsarpa_details?.present === true ? "Red" : "Green"
          }`,
        }}
      >
        {kundli?.kalsarpa_details?.one_line}
      </h3>
    </div>
  );
};

export default KalsarpaDosha;
