import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Footer } from "../../Components/Layouts";
import { AstrologyBanner } from "../../Components/UI";
import LandingHeader from "./LandingHeader";
import MainSlider from "./MainSlider";

const Landing = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const homeRef = useRef(null);

  useEffect(() => {
    if (token) {
      navigate("/dashboard ");
    } else {
      localStorage.clear();
    }
  }, [navigate, token]);

  const scrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToServices = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <LandingHeader
        scrollToAbout={scrollToAbout}
        scrollToServices={scrollToServices}
        scrollToHome={scrollToHome}
      />
      <main className="main_content">
        <div id="home" ref={homeRef}>
          <MainSlider />
          <AstrologyBanner />
        </div>

       

        <section className="services_content d-none" ref={servicesRef} id="services">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-12">
                <div className="heding_box">
                  <h2> Services For You </h2>
                  <img src="/assets/images/1-2-1.png" alt="heading" />
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected hummer.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_a.svg" alt="icon" />
                  <h4 className="as-subheading">Birth Journal</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_b.svg" alt="icon" />
                  <h4 className="as-subheading">Vastu Shastra</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_c.svg" alt="icon" />
                  <h4 className="as-subheading">Face Reading</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_d.svg" alt="icon" />
                  <h4 className="as-subheading">Lal Kitab</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_e.svg" alt="icon" />
                  <h4 className="as-subheading">Crystal Ball</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_f.svg" alt="icon" />
                  <h4 className="as-subheading">Kundli Dosh</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_g.svg" alt="icon" />
                  <h4 className="as-subheading">Matrimony</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-6 m-0 p-2">
                <div className="as-service-box ">
                  <img src="/assets/images/ser_h.svg" alt="icon" />
                  <h4 className="as-subheading">Year Analysis</h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available
                  </p>
                  <Link to="#" className="al-link">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};
export default Landing;
