import React, { useState } from "react";

const KundliChart = ({ kundli }) => {
  const [chart, setChart] = useState("lagna_birth_chart");

  const handleChartChange = (event) => {
    setChart(event.target.value);
  };
  return (
    <div className="kundli_basi row justify-content-center m-0">
      <div className="col-lg-12 mb-3 ">
        <h4 className="heding_bx">Kundli Charts</h4>
      </div>
      <div className="col-lg-4 col-12 my-3">
        <select
          className="form-control"
          value={chart}
          onChange={handleChartChange}
        >
          <option value="lagna_birth_chart">Birth Chart</option>
          <option value="chalit_chart">Chalit Chart</option>
          <option value="moon_chart">Moon Chart</option>
          <option value="sun_chart">Sun Chart</option>
          <option value="hora_chart">Hora Chart</option>
          <option value="dreshkan_chart">Dreshkan Chart</option>
          <option value="chathurthamasha_chart">Chathurthamasha Chart</option>
          <option value="panchmansha_chart">Panchmansha Chart</option>
          <option value="saptamansha_chart">Saptamansha Chart</option>
          <option value="ashtamansha_chart">Ashtamansha Chart</option>
          <option value="navmansha_chart">Navmansha Chart</option>
          <option value="dashamansha_chart">Dashamansha Chart</option>
          <option value="dwadasha_chart">Dwadashamansha Chart</option>
          <option value="shodashamsha_chart">Shodashmsha Chart</option>
          <option value="vishamansha_chart">Vishamansha Chart</option>
          <option value="chaturvimshamsha_chart">Chaturvimshamsha Chart</option>
          <option value="bhamsha_chart">Bhamsha Chart</option>
          <option value="trishamansha_chart">Trishamansha Chart</option>
          <option value="khavedamsha_chart">Khavedamansha Chart</option>
          <option value="akshvedansha_chart">Akshvedansha Chart</option>
          <option value="shashtymsha_chart">Shashtymsha Chart</option>
        </select>
      </div>
      <div className="col-lg-12 col-12">
        <div
          className="text-center chat_box"
          dangerouslySetInnerHTML={{
            __html:
              chart === "chalit_chart"
                ? kundli[`${chart}`]
                : kundli[`${chart}`]?.svg,
          }}
        ></div>
      </div>

      <div className=" col-lg-12 col-12">
        <div className="reposive_teble">
          <table className="w-100 m-0 pt-2">
            <thead>
              <tr>
                <th>Planets</th>
                <th>R</th>
                <th>Sign</th>
                <th>Degree</th>
                <th>Sign Lord</th>
                <th>Nakshatra</th>
                <th>Nakshatra Lord</th>
                <th>House</th>
              </tr>
            </thead>
            <tbody>
              {kundli?.planets?.map((planet) => (
                <tr key={planet?.id}>
                  <td>{planet.name}</td>
                  <td>{planet.isRetro === "true" ? "R" : "-"}</td>
                  <td>{planet.sign}</td>
                  <td>{planet.normDegree}</td>
                  <td>{planet.signLord}</td>
                  <td>{planet.nakshatra}</td>
                  <td>{planet.nakshatraLord}</td>
                  <td>{planet.house}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KundliChart;
