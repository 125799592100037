import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { AstrologyBanner, LineLoader } from "../Components/UI";
import { createMatchingKundli } from "../store/matching-kundli/matchingKundli-action";
import useAuthApp from "../hooks/useAuthApp";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Ashtkoota,
  AstroDetails,
  BirthDetails,
  Charts,
  MatchConclusion,
  MatchManglik,
} from "../Components/MatchingKundli/index";
import { onFailedMsg } from "../helper/showHideAlert";

const KundaliMatching = () => {
  const { matchingKundli } = useSelector((state) => state.matchingKundli);
  const { navigate, authFetch, dispatch } = useAuthApp();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("Birth Details");
  const firstTime = localStorage.getItem("firstTime");
  const location = useLocation();
  const [downloadLoader, setDowanloaderLoader] = useState(false);

  useEffect(() => {
    if (firstTime) {
      localStorage.removeItem("firstTime");
    } else {
      const createNewKundli = async (authFetch, navigate, state, dispatch) => {
        setLoading(true);
        await dispatch(createMatchingKundli(authFetch, navigate, state));
        setLoading(false);
      };
      createNewKundli(authFetch, navigate, location.state, dispatch);
    }
  }, [matchingKundli]);

  const handleDownload = (pdfUrl) => {
    axios({
      url: pdfUrl,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloaded_file.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDowanloaderLoader(false);
      })
      .catch((error) => {
        onFailedMsg(dispatch, "Failed to download,please try again");
        setDowanloaderLoader(false);
      });
  };

  const onDownloadKundali = async () => {
    try {
      setDowanloaderLoader(true);
      if (!downloadLoader && location?.state) {
        const { data } = await authFetch.post(
          "/kundali/kundli-matching-download",
          location?.state
        );
        if (data && data?.status === true) {
          handleDownload(data.kundaliURL);
        } else {
          onFailedMsg(dispatch, "Failed to download,please try again");
          setDowanloaderLoader(false);
        }
      }
    } catch (e) {
      onFailedMsg(dispatch, "Failed to download,please try again");
      setDowanloaderLoader(false);
    }
  };

  return (
    <main className="main_content">
      <div className="al-breadcrumb-wrapper text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="al-breadcrumb-container">
                <h1>kundli Matching</h1>
                <ul>
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>kundli Matching</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="Kundli_content">
        <div className="container">
          <div className="row justify-content-center">
            {location.state === null ? (
              <h3>
                Please Go To{" "}
                <Link className="btn btn-warning" to="/dashboard">
                  DashBoard
                </Link>{" "}
                And Select User
              </h3>
            ) : loading ? (
              <h3 className="box_loading">
                Wait until you request to create a Kundali.....
              </h3>
            ) : (
              <div>
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-12">
                    <div className="heding_card">
                      <h2>
                        Viewing Details of {location?.state?.userData?.m_name}{" "}
                        with {location?.state?.userData?.f_name}
                      </h2>

                      <div className="box_button">
                        {/* <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#Setting"
                    className="pofil_butt"
                  >
                    Setting
                  </Link> */}

                        {!loading &&
                          location &&
                          location?.state &&
                          Object.keys(location?.state)?.length > 0 && (
                            <div>
                              <p
                                className={`pofil_butt `}
                                style={{
                                  cursor: downloadLoader ? "wait" : "pointer",
                                }}
                                disabled={downloadLoader}
                                onClick={onDownloadKundali}
                              >
                                {" "}
                                <img
                                  width="18"
                                  src="/assets/images/pdf.png"
                                  alt="icon"
                                  className={`mx-2 `}
                                />
                                {downloadLoader
                                  ? "Downloading...."
                                  : "Kundli download"}
                              </p>
                              {downloadLoader && <LineLoader />}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="kundli_teb_box">
                    <ul className="asto_tab">
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Birth Details" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Birth Details");
                          }}
                        >
                          Birth Details
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Astro Details" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Astro Details");
                          }}
                        >
                          Astro Details
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Charts" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Charts");
                          }}
                        >
                          Charts
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Ashtkoota" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Ashtkoota");
                          }}
                        >
                          Ashtkoota
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Match Manglik" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Match Manglik");
                          }}
                        >
                          Match Manglik
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Match Conclusion" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Match Conclusion");
                          }}
                        >
                          Match Conclusion
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                {mode === "Birth Details" && (
                  <BirthDetails matchingKundli={matchingKundli} />
                )}
                {mode === "Astro Details" && (
                  <AstroDetails matchingKundli={matchingKundli} />
                )}
                {mode === "Charts" && (
                  <Charts matchingKundli={matchingKundli} />
                )}
                {mode === "Ashtkoota" && (
                  <Ashtkoota matchingKundli={matchingKundli} />
                )}
                {mode === "Match Manglik" && (
                  <MatchManglik matchingKundli={matchingKundli} />
                )}
                {mode === "Match Conclusion" && (
                  <MatchConclusion matchingKundli={matchingKundli} />
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      <AstrologyBanner />
    </main>
  );
};

export default KundaliMatching;
