import Slider from "react-slick";

const MainSlider = () => {
  const settings = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    waitForAnimate: false,
  };
  return (
    <div className="dummy_box_slider ">
      <Slider {...settings}>
        <div>
          <img src="/assets/images/horoscope1.jpg" alt="img" />
        </div>
        <div>
          <img src="/assets/images/horoscope2.jpg" alt="img" />
        </div>
        <div>
          <img src="/assets/images/horoscope3.jpg" alt="img" />
        </div>
        <div>
          <img src="/assets/images/horoscope4.jpg" alt="img" />
        </div>
      </Slider>
    </div>
  );
};

export default MainSlider;
