import { createSlice } from "@reduxjs/toolkit";

const kundliSlice = createSlice({
  name: "kundli",
  initialState: {
    kundli: {},
  },
  reducers: {
    updateKundli(state, action) {
      state.kundli = action.payload.kundli;
    },
  },
});

export const kundliActions = kundliSlice.actions;
export default kundliSlice;
