import { onFailedMsg } from "../../helper/showHideAlert";
import { matchingKundliActions } from "./matchingKundli-slice";

export const createMatchingKundli = (authFetch, navigate, body) => {
  return async (dispatch) => {
    try {
      let { data } = await authFetch.post(`/kundali/kundli-matching`, body);
      if (data && data?.status === true) {
        dispatch(
          matchingKundliActions.updateMatchingKundli({
            matchingKundli: data.data,
          })
        );
        localStorage.setItem("firstTime", true);
        navigate("/kundli-matching", { state: body });
      } else {
        onFailedMsg(dispatch, data?.error);
      }
    } catch (error) {
      console.log("erro", error);
      onFailedMsg(dispatch, error?.response?.data?.error);
    }
  };
};
