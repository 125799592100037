import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { AstrologyBanner, LineLoader } from "../Components/UI/index";
import { useSelector } from "react-redux";
import useAuthApp from "../hooks/useAuthApp";
import { createKundli } from "../store/kundli/kundli-action";
import {
  AstroProfile,
  FavorablePoints,
  GemstoneSuggestion,
  HouseCusps,
  KalsarpaDosha,
  KundliChart,
  ManglikDosha,
  NumerologyPrediction,
  PitraDosha,
  PlanetAshtak,
  RudrakshaSuggestion,
  SadhesatiCycle,
  Sarvashtak,
  VimshottariDasha,
  YoginiDasha,
} from "../Components/Kundli/index";
import { onFailedMsg } from "../helper/showHideAlert";

const Kundali = () => {
  const { navigate, authFetch, dispatch } = useAuthApp();
  const { kundli } = useSelector((state) => state.kundli);
  const [downloadLoader, setDowanloaderLoader] = useState(false);
  const [mode, setMode] = useState("Astro Profile");
  const [loading, setLoading] = useState(false);
  const firstTime = localStorage.getItem("firstTime");
  const location = useLocation();

  useEffect(() => {
    if (firstTime) {
      localStorage.removeItem("firstTime");
    } else {
      const createNewKundli = async (authFetch, navigate, state, dispatch) => {
        setLoading(true);
        await dispatch(createKundli(authFetch, navigate, state));
        setLoading(false);
      };
      if (location?.state) {
        createNewKundli(authFetch, navigate, location.state, dispatch);
      }
    }
  }, [kundli]);

  const handleDownload = (pdfUrl) => {
    axios({
      url: pdfUrl,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "downloaded_file.pdf");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDowanloaderLoader(false);
      })
      .catch((error) => {
        onFailedMsg(dispatch, "Failed to download,please try again");
        setDowanloaderLoader(false);
      });
  };

  const onDownloadKundali = async () => {
    try {
      setDowanloaderLoader(true);
      const body = {
        userData: {
          name: location?.state?.userData?.nameu,
          gender: location?.state?.userData?.gender,
          day: location?.state?.userData?.day,
          month: location?.state?.userData?.month,
          year: location?.state?.userData?.year,
          lat: location?.state?.userData?.lat,
          lon: location?.state?.userData?.lon,
          birth: location?.state?.userData?.birth,
          profile_id: 336494,
          tzone: location?.state?.userData?.tzone,
          hour: location?.state?.userData?.hour,
          min: location?.state?.userData?.min,
          second: 0,
          lang: location?.state?.userData?.language,
          chart: "north",
          ayanmasha: "LAHIRI",
          node: "true",
          house: "shripati",
        },
        lang: location?.state?.lang,
      };
      if (!downloadLoader && location?.state) {
        const { data } = await authFetch.post(
          "/kundali/download-horoscope",
          body
        );
        if (data && data?.status === true) {
          handleDownload(data.kundaliURL);
        } else {
          onFailedMsg(dispatch, "Failed to download,please try again");
          setDowanloaderLoader(false);
        }
      }
    } catch (e) {
      onFailedMsg(dispatch, "Failed to download,please try again");
      setDowanloaderLoader(false);
    }
  };

  return (
    <main className="main_content">
      <div className="al-breadcrumb-wrapper text-center">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="al-breadcrumb-container">
                <h1>Kundli details</h1>
                <ul>
                  <li>
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li>Kundli details</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="Kundli_content">
        <div className="container">
          <div className="row justify-content-center">
            {location.state === null ? (
              <h3 className="box_loading">
                Please Go To{" "}
                <Link className="btn btn-warning" to="/dashboard">
                  DashBoard
                </Link>{" "}
                And Select User
              </h3>
            ) : loading ? (
              <div>
                <h3 className="box_loading">
                  Wait until you request to create a Kundali....
                </h3>
              </div>
            ) : (
              <div>
                <div className="row justify-content-center">
                  <div className="col-lg-12 col-12">
                    <div className="heding_card">
                      <h2>
                        {" "}
                        Viewing Details of {
                          location?.state?.userData?.nameu
                        }{" "}
                      </h2>

                      <div className="box_button">
                        {/* <p to="#" className="pofil_butt cursor-pointer">
                    Select Profile
                  </p>
                  <p
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_new"
                    className="pofil_butt cursor-pointer"
                  >
                    Edit
                  </p>
                  <p
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#add_new"
                    className="pofil_butt cursor-pointer"
                  >
                    Add New
                  </p> */}
                        {/* <p
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#Setting"
                    className="pofil_butt cursor-pointer"
                  >
                    Setting
                  </p> */}
                        {!loading &&
                          location &&
                          location?.state &&
                          Object.keys(location?.state)?.length > 0 && (
                            <div>
                              <p
                                className={`pofil_butt disabled`}
                                style={{
                                  cursor: downloadLoader ? "wait" : "pointer",
                                }}
                                disabled={downloadLoader}
                                onClick={onDownloadKundali}
                              >
                                {" "}
                                <img
                                  width="18"
                                  src="/assets/images/pdf.png"
                                  alt="icon"
                                  className={`mx-2 `}
                                />
                                {downloadLoader
                                  ? "Downloading...."
                                  : "Kundli download"}
                              </p>
                              {downloadLoader && <LineLoader />}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-12">
                  <div className="kundli_teb_box">
                    <ul className="asto_tab">
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Astro Profile" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Astro Profile");
                          }}
                        >
                          Astro Profile
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Kundli Chart" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Kundli Chart");
                          }}
                        >
                          Kundli Chart
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "House Cusps" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("House Cusps");
                          }}
                        >
                          House Cusps
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Planet Ashtak" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Planet Ashtak");
                          }}
                        >
                          Planet Ashtak
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Sarvashtak" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Sarvashtak");
                          }}
                        >
                          Sarvashtak
                        </p>
                      </li>

                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Vimshottari Dasha" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Vimshottari Dasha");
                          }}
                        >
                          Vimshottari Dasha
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Yogini Dasha" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Yogini Dasha");
                          }}
                        >
                          Yogini Dasha
                        </p>
                      </li>

                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Kalsarpa Dosha" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Kalsarpa Dosha");
                          }}
                        >
                          Kalsarpa Dosha
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Manglik Dosha" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Manglik Dosha");
                          }}
                        >
                          Manglik Dosha
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Pitra Dosha" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Pitra Dosha");
                          }}
                        >
                          Pitra Dosha
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Sadhesati Cycle" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Sadhesati Cycle");
                          }}
                        >
                          Sadhesati Cycle
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Gemstone Suggestion" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Gemstone Suggestion");
                          }}
                        >
                          Gemstone Suggestion
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Rudraksha Suggestion" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Rudraksha Suggestion");
                          }}
                        >
                          Rudraksha Suggestion
                        </p>
                      </li>

                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Favorable points" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Favorable points");
                          }}
                        >
                          Favorable points
                        </p>
                      </li>
                      <li className="cursor-pointer">
                        <p
                          className={`link_teb ${
                            mode === "Numerology Prediction" ? "active" : ""
                          }`}
                          onClick={() => {
                            setMode("Numerology Prediction");
                          }}
                        >
                          Numerology Prediction
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                {mode === "Astro Profile" && <AstroProfile kundli={kundli} />}
                {mode === "Kundli Chart" && <KundliChart kundli={kundli} />}
                {mode === "House Cusps" && <HouseCusps kundli={kundli} />}
                {mode === "Planet Ashtak" && <PlanetAshtak kundli={kundli} />}
                {mode === "Sarvashtak" && <Sarvashtak kundli={kundli} />}
                {mode === "Vimshottari Dasha" && (
                  <VimshottariDasha kundli={kundli} />
                )}
                {mode === "Yogini Dasha" && <YoginiDasha kundli={kundli} />}
                {mode === "Kalsarpa Dosha" && <KalsarpaDosha kundli={kundli} />}
                {mode === "Manglik Dosha" && <ManglikDosha kundli={kundli} />}
                {mode === "Pitra Dosha" && <PitraDosha kundli={kundli} />}
                {mode === "Sadhesati Cycle" && (
                  <SadhesatiCycle kundli={kundli} />
                )}
                {mode === "Gemstone Suggestion" && (
                  <GemstoneSuggestion kundli={kundli} />
                )}
                {mode === "Rudraksha Suggestion" && (
                  <RudrakshaSuggestion kundli={kundli} />
                )}
                {mode === "Favorable points" && (
                  <FavorablePoints kundli={kundli} />
                )}
                {mode === "Numerology Prediction" && (
                  <NumerologyPrediction kundli={kundli} />
                )}
              </div>
            )}
          </div>
        </div>
      </section>

      <AstrologyBanner />
    </main>
  );
};

export default Kundali;
