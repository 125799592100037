import React from "react";

const HouseCusps = ({ kundli }) => {
  return (
    <div className="kundli_basi ">
      {" "}
      <div className="reposive_teble ">
        <table className="w-100">
          <thead>
            <tr>
              <th>House</th>
              <th>Sign</th>
              <th>Bhav Madhya</th>
              <th>Sign</th>
              <th>Bhav Sandhi</th>
            </tr>
          </thead>
          <tbody>
            {kundli?.bhavmadhyasudhi?.bhav_madhya?.map((house, index) => {
              return (
                <tr key={index}>
                  <td>{house.house}</td>
                  <td>{house.sign}</td>
                  <td>{house.norm_degree}</td>
                  <td>{kundli?.bhavmadhyasudhi?.bhav_sandhi[index].sign}</td>
                  <td>
                    {kundli?.bhavmadhyasudhi?.bhav_sandhi[index].norm_degree}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* <table>
        <thead>
          <tr>
            <th>House</th>
            <th>Sign</th>
            <th>Bhav Sandhi</th>
          </tr>
        </thead>
        <tbody>
          {kundli?.bhavmadhyasudhi?.bhav_sandhi?.map((house, index) => {
            return (
              <tr key={index}>
                <td>{house.house}</td>
                <td>{house.sign}</td>
                <td>{house.norm_degree}</td>
              </tr>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
};

export default HouseCusps;
