import { onFailedMsg, onSuccess } from "../../helper/showHideAlert";
import { kundliUserActions } from "./kundliUser-slice";

export const createKundliUser = (body, authFetch) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await authFetch.post(`/kundali/create-user`, body);

      if (data && data.status === true) {
        const { kundliUsers } = getState().kundliUser;
        const updatedKundliUsers = [data.data, ...kundliUsers];
        dispatch(
          kundliUserActions.updateKundliUsers({
            kundliUsers: updatedKundliUsers,
          })
        );
        onSuccess(dispatch, data.message);
      } else {
        onFailedMsg(dispatch, data.error);
      }
    } catch (error) {
      onFailedMsg(dispatch, error.response?.data?.error);
    }
  };
};

export const getKundliUsersList = (authFetchGET) => {
  return async (dispatch) => {
    try {
      let { data } = await authFetchGET.get(`/kundali/users`);
      if (data && data?.status === true) {
        const reverseData = data.data.reverse();
        dispatch(
          kundliUserActions.updateKundliUsers({ kundliUsers: reverseData })
        );
      }
    } catch (error) {
      onFailedMsg(dispatch, error?.response?.data?.error);
    }
  };
};

export const deleteKundliUser = (id, authFetch) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await authFetch.delete(`/kundali/${id}`);

      if (data && data.status === true) {
        const { kundliUsers } = getState().kundliUser;
        const updatedKundliUsers = kundliUsers.filter(
          (user) => user._id !== id
        );

        dispatch(
          kundliUserActions.updateKundliUsers({
            kundliUsers: updatedKundliUsers,
          })
        );

        onSuccess(dispatch, data.message);
      }
    } catch (error) {
      onFailedMsg(dispatch, error?.response?.data?.error);
    }
  };
};

export const editKundliUserDetails = (id, body, authFetch) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await authFetch.patch(`/kundali/${id}`, body);

      if (data && data.status === true) {
        const { kundliUsers } = getState().kundliUser;

        const userIndex = kundliUsers.findIndex((user) => user._id === id);

        if (userIndex !== -1) {
          const updatedKundliUsers = [...kundliUsers];
          updatedKundliUsers[userIndex] = { ...data.data };

          dispatch(
            kundliUserActions.updateKundliUsers({
              kundliUsers: updatedKundliUsers,
            })
          );

          onSuccess(dispatch, data.message);
        } else {
          onFailedMsg(dispatch, "User not found");
        }
      } else {
        onFailedMsg(dispatch, data.error);
      }
    } catch (error) {
      onFailedMsg(dispatch, error?.response?.data?.error);
    }
  };
};
