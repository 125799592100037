import { Fragment, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getYear, getMonth } from "date-fns";
import { range } from "lodash";
import { BsCalendar2Date } from "react-icons/bs";

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <input
    id="DateTimeInpt"
    placeholder="DD-MM-YYYY"
    className="form-control date-box"
    type="text"
    value={value}
    onClick={onClick}
    readOnly
    style={{ cursor: "pointer" }}
    ref={ref}
  />
));

const CustomHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const years = range(1970, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div style={{ margin: 10, display: "flex", justifyContent: "center" }}>
      <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {"<"}
      </button>
      <select
        value={getYear(date)}
        onChange={({ target: { value } }) => changeYear(value)}
      >
        {years.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <select
        value={months[getMonth(date)]}
        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
      >
        {months.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>

      <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {">"}
      </button>
    </div>
  );
};

const DatePrompt = ({ selected, onChange, maxDate }) => {
  return (
    <Fragment>
      <BsCalendar2Date className="icon_box" />
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
          />
        )}
        selected={selected}
        onChange={onChange}
        maxDate={maxDate}
        customInput={<CustomInput />}
        dateFormat="dd-MM-yyyy"
        showMonthDropdown
        showYearDropdown
      />
    </Fragment>
  );
};

export default DatePrompt;
