import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: {
      user_id: undefined,
      userName: "",
      mobileNumber: "",
      role: "user",
    },
  },
  reducers: {
    setUserProfile(state, action) {
      const { _id, userName, mobileNumber, role } = action.payload.user;
      state.user = {
        user_id: _id,
        userName,
        mobileNumber,
        role,
      };
    },
  },
});

export const { setUserProfile } = userSlice.actions;
export default userSlice;
