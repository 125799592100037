import React from "react";

const SadhesatiCycle = ({ kundli }) => {
  return (
    <div className="kundli_basi row m-0">
      <div>
        <h4 className="heding_bx">What is Sadhesati Dosha ?</h4>
        <p className="mt-3">
          Sadhe Sati refers to the seven-and-a-half year period in which Saturn
          moves through three signs, the moon sign, one before the moon and the
          one after it. Sadhe Sati starts when Saturn (Shani) enters the 12th
          sign from the birth Moon sign and ends when Saturn leaves 2nd sign
          from the birth Moon sign. Since Saturn approximately takes around two
          and half years to transit a sign which is called Shanis dhaiya it
          takes around seven and half year to transit three signs and that is
          why it is known as Sadhe Sati. Generally Sade-Sati comes thrice in a
          horoscope in the life time - first in childhood, second in youth &
          third in old-age. First Sade-Sati has effect on education & parents.
          Second Sade-Sati has effect on profession, finance & family. The last
          one affects health more than anything else.
        </p>
      </div>
      <div className="reposive_teble ">
        <table className="w-100">
          <thead>
            <tr>
              <th>Date</th>
              <th>Moon Sign</th>
              <th>Saturn Sign</th>
              <th>Retro</th>
              <th>Type</th>
              <th>Summary</th>
            </tr>
          </thead>
          <tbody>
            {kundli?.sadhesati_life_details?.map((details, index) => (
              <tr key={index}>
                <td>{details?.date}</td>
                <td>{details?.moon_sign}</td>
                <td>{details?.saturn_sign}</td>
                <td>{details?.is_saturn_retrograde}</td>
                <td>{details?.type}</td>
                <td>{details?.summary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SadhesatiCycle;
