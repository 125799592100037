import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useAuthApp from "../../hooks/useAuthApp";
import { onFetchUserProfileHandler } from "../../store/user/user-action";
import { onLogoutHandler } from "../../store/auth/auth-action";
import { AppLogo } from "../UI";
import { ChnagePasswordModal } from "../../models";

const Header = () => {
  const [openDropDown, setOpenDropDown] = useState(false);
  const userDropdownRef = useRef();
  const [changePasswordModal, setChangePasswordModal] = useState({
    isOpen: false,
  });
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.user);
  const { token } = useSelector((state) => state.auth);
  const { authFetchGET, dispatch, navigate, authFetch } = useAuthApp();

  useEffect(() => {
    dispatch(onFetchUserProfileHandler(authFetchGET));
  }, []);

  const toggleDropDown = () => {
    setOpenDropDown(!openDropDown);
  };

  const logoutHanler = async () => {
    setLoading(true);
    await dispatch(onLogoutHandler(navigate, authFetch));
    setLoading(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setOpenDropDown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header className="main-header">
      <div className="topbar">
        <div className="d-flex">
          <AppLogo token={token} />
        </div>

        <div className="user-noti-box">
          <div className="dropdown">
            <h6>
              <b>{user?.userName}</b>
            </h6>
          </div>
          <div className="navbar user-detle" ref={userDropdownRef}>
            <div
              className="nav-link cursor-pointer "
              to="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={toggleDropDown}
            >
              <div className="user-box icon">
                {" "}
                <img src="/assets/images/user.png" alt="user" />
              </div>
            </div>

            <ul
              className={`dropdown-menu  ${
                openDropDown ? "d-block mt-12" : ""
              }`}
              aria-labelledby="navbarDropdown"
            >
              <li>
                <div className="user-tsxt mt-2 px-2">
                  <h5 onClick={toggleDropDown}>
                    <img
                      src="/assets/images/add_uaer.svg"
                      alt="user"
                      width="20"
                    />{" "}
                    +91 {user.mobileNumber}{" "}
                  </h5>
                </div>
              </li>
              <hr className="dropdown-divider" />
              <li>
                <div
                  className={`dropdown-item cursor-pointer `}
                  to="#"
                  onClick={() => {
                    if (!loading) {
                      toggleDropDown();
                      setChangePasswordModal({ isOpen: true });
                    }
                  }}
                >
                  Change password
                </div>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <div
                  className={`dropdown-item cursor-pointer ${
                    loading ? "disabled" : ""
                  }`}
                  to="#"
                  onClick={logoutHanler}
                >
                  {" "}
                  {loading ? "Loading..." : "Log Out"}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {changePasswordModal.isOpen && (
        <ChnagePasswordModal
          isOpen={changePasswordModal.isOpen}
          onHide={() => setChangePasswordModal({ isOpen: false })}
        />
      )}
    </header>
  );
};

export default Header;
