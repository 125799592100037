import React from "react";

const GemstoneSuggestion = ({ kundli }) => {
  return (
    <div className="kundli_basi row m-0">
      {/* This loop is for overview of gem */}
      {kundli?.basic_gem_suggestion?.map((gem, index) => (
        <div className="col-lg-4 col-12 box_gemstone_card" key={index}>
          <img src={`${gem.imgURL}`} alt="Gem Img" />
          <p>{gem.name}</p>
          <p>{gem.gem_key}</p>
          <p>{gem.semi_gem}</p>
          <p>{gem.wear_finger}</p>
          <p>{gem.weight_caret}</p>
          <p>{gem.wear_metal}</p>
          <p>{gem.wear_day}</p>
          <p>{gem.gem_deity}</p>
          <p>{gem.label}</p>
        </div>
      ))}
      {/* This loop is for Details of gem*/}
      {kundli?.basic_gem_suggestion?.map((gem, index) => (
        <div className="col-lg-4 col-12 box_gemstone_card" key={index}>
          <h5>{gem?.defination?.label}</h5>
          <p>{gem?.defination?.text}</p>
          <h5>{gem?.time?.label}</h5>
          <p>{gem?.time?.text}</p>
          <h5>{gem?.weight_metal?.label}</h5>
          <p>{gem?.weight_metal?.text}</p>
          <h5>{gem?.mantra?.label}</h5>
          <p>{gem?.mantra?.text}</p>
          <h5>{gem?.substitutes?.label}</h5>
          <p>{gem?.substitutes?.text}</p>
          <h5>{gem?.rituals?.label}</h5>
          <p>{gem?.rituals?.text}</p>
          <h5>{gem?.caution?.label}</h5>
          <p>{gem?.caution?.text}</p>
        </div>
      ))}
    </div>
  );
};

export default GemstoneSuggestion;
